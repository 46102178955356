import React from "react";
import "leaflet/dist/leaflet.css";
import 'react-reflex/styles.css'
import MapLayout from "./MapLayout";
import {LayoutContextProvider} from "../../context/LayoutContextProvider";
import {MapContextProvider} from "../../context/MapContextProvider";
import {RouteContextProvider} from "../../context/RouteContextProvider";
import MainLayout from "../../components/layout/MainLayout";
import {UserContextProvider} from "../../context/UserContextProvider";
import {AddressBookContextProvider} from "../../context/AddressBookContextProvider";

const MapPage = (): JSX.Element => {
  return (
    <UserContextProvider>
      <LayoutContextProvider>
        <MapContextProvider>
          <AddressBookContextProvider>
            <RouteContextProvider>
              <MainLayout>
                <MapLayout/>
              </MainLayout>
            </RouteContextProvider>
          </AddressBookContextProvider>
        </MapContextProvider>
      </LayoutContextProvider>
    </UserContextProvider>
  );
};

export default MapPage;
