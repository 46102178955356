import {Drawer, Space} from 'antd';
import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {PagedCollectionRouteResponse, RouteApi, RouteResponse} from "../../api/client";
import {buildConfiguration} from "../../api";
import {useUserContext} from "../../context/UserContextProvider";
import {AxiosResponse} from "axios";
import bgImg from "../../assets/images/route/bg.png";
import {useLayoutContext} from "../../context/LayoutContextProvider";

interface RouteListProps {
  open: boolean;
}

const BottomImage = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 400px;
`;

const Route = styled.div`
  background: url(${bgImg}) no-repeat;
  background-size: cover;
  cursor: pointer;
  width: 200px;
  height: 100px;
  border: 2px solid #eee;
  border-radius: 5px;
  padding: 10px;

  &:hover {
    border: 2px solid #ccc;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 12px;
  margin-top: 5px;
`;

const RouteList = (props: RouteListProps): JSX.Element => {
  const {accessToken} = useUserContext()
  const [error, setError] = useState<string | null>(null);
  const {open} = props;
  const navigate = useNavigate();
  const {
    setSelectedTool
  } = useLayoutContext();

  const [routes, setRoutes] = useState<RouteResponse[] | null>(null);

  const routeApi = useMemo(() => {
    return new RouteApi(buildConfiguration(accessToken));
  }, [accessToken]);

  const onClose = () => {
    setSelectedTool(null);
  };

  const selectRoute = (routeId: string) => {
    navigate(`/routes/${routeId}`);
    setSelectedTool(null);
  };

  useEffect(() => {
    if (!open) {
      setRoutes(null);
      return;
    }

    routeApi
      .filterRoutes()
      .then((response: AxiosResponse<PagedCollectionRouteResponse>) => {
        setRoutes(response.data.items || null);
      })
      .catch(err => {
        setError(err.message);
      });
  }, [open]);

  return (
    <Drawer
      title={(
        <div style={{paddingLeft: 25, fontStyle: "italic", fontFamily: "Mochiy Pop One"}}>
          Saved Routes
        </div>
      )}
      placement={"bottom"}
      closable={true}
      onClose={onClose}
      open={open}
      bodyStyle={{padding: 10}}
      style={{height: 600}}
    >
      {
        routes && (
          <Space size={[8, 16]} wrap>
            {
              routes.map(route => {
                return (
                  <Route key={route.id} onClick={() => selectRoute(route.id!)}>
                    <Title>
                      {route.name}
                    </Title>
                    <Description>
                      {route.description}
                    </Description>
                  </Route>
                );
              })
            }
          </Space>
        )
      }
    </Drawer>
  );
};

export default RouteList;
