import {createContext} from "react";
import {UserResponse} from "../api/client";

export type UserContextType = {
  accessToken: string | null;
  user: UserResponse | null;
  logout: () => void;
};

export const UserDefaultContext: UserContextType = {
  accessToken: null,
  user: null,
  logout: () => {}
};

export default createContext<UserContextType>(UserDefaultContext);
