/* tslint:disable */
/* eslint-disable */
/**
 * Route & Ride API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RouteDestinationAddressCreateRequest } from '../model';
// @ts-ignore
import { RouteDestinationAddressRemoveRequest } from '../model';
// @ts-ignore
import { RouteDestinationCreateRequest } from '../model';
// @ts-ignore
import { RouteDestinationDeleteRequest } from '../model';
// @ts-ignore
import { RouteDestinationOrderRequest } from '../model';
// @ts-ignore
import { RouteDestinationResponse } from '../model';
// @ts-ignore
import { RouteDestinationUpdateRequest } from '../model';
/**
 * DestinationApi - axios parameter creator
 * @export
 */
export const DestinationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add destination to address book
         * @param {RouteDestinationAddressCreateRequest} routeDestinationAddressCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToAddressBook: async (routeDestinationAddressCreateRequest: RouteDestinationAddressCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routeDestinationAddressCreateRequest' is not null or undefined
            assertParamExists('addToAddressBook', 'routeDestinationAddressCreateRequest', routeDestinationAddressCreateRequest)
            const localVarPath = `/v1/destinations/add-to-address-book`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(routeDestinationAddressCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add route destination
         * @param {RouteDestinationCreateRequest} routeDestinationCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDestination: async (routeDestinationCreateRequest: RouteDestinationCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routeDestinationCreateRequest' is not null or undefined
            assertParamExists('createDestination', 'routeDestinationCreateRequest', routeDestinationCreateRequest)
            const localVarPath = `/v1/destinations/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(routeDestinationCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete route destination
         * @param {RouteDestinationDeleteRequest} routeDestinationDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDestination: async (routeDestinationDeleteRequest: RouteDestinationDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routeDestinationDeleteRequest' is not null or undefined
            assertParamExists('deleteDestination', 'routeDestinationDeleteRequest', routeDestinationDeleteRequest)
            const localVarPath = `/v1/destinations/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(routeDestinationDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get route destination by id
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDestinationById: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/destinations/by-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get route destinations
         * @param {string} [routeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDestinationsByRouteId: async (routeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/destinations/by-route-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (routeId !== undefined) {
                localVarQueryParameter['route_id'] = routeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Order route destinations
         * @param {RouteDestinationOrderRequest} routeDestinationOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderDestinations: async (routeDestinationOrderRequest: RouteDestinationOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routeDestinationOrderRequest' is not null or undefined
            assertParamExists('orderDestinations', 'routeDestinationOrderRequest', routeDestinationOrderRequest)
            const localVarPath = `/v1/destinations/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(routeDestinationOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove destination from address book
         * @param {RouteDestinationAddressRemoveRequest} routeDestinationAddressRemoveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromAddressBook: async (routeDestinationAddressRemoveRequest: RouteDestinationAddressRemoveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routeDestinationAddressRemoveRequest' is not null or undefined
            assertParamExists('removeFromAddressBook', 'routeDestinationAddressRemoveRequest', routeDestinationAddressRemoveRequest)
            const localVarPath = `/v1/destinations/remove-from-address-book`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(routeDestinationAddressRemoveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update route destination
         * @param {RouteDestinationUpdateRequest} routeDestinationUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDestination: async (routeDestinationUpdateRequest: RouteDestinationUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routeDestinationUpdateRequest' is not null or undefined
            assertParamExists('updateDestination', 'routeDestinationUpdateRequest', routeDestinationUpdateRequest)
            const localVarPath = `/v1/destinations/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(routeDestinationUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DestinationApi - functional programming interface
 * @export
 */
export const DestinationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DestinationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add destination to address book
         * @param {RouteDestinationAddressCreateRequest} routeDestinationAddressCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToAddressBook(routeDestinationAddressCreateRequest: RouteDestinationAddressCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteDestinationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addToAddressBook(routeDestinationAddressCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add route destination
         * @param {RouteDestinationCreateRequest} routeDestinationCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDestination(routeDestinationCreateRequest: RouteDestinationCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteDestinationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDestination(routeDestinationCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete route destination
         * @param {RouteDestinationDeleteRequest} routeDestinationDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDestination(routeDestinationDeleteRequest: RouteDestinationDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteDestinationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDestination(routeDestinationDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get route destination by id
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDestinationById(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteDestinationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDestinationById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get route destinations
         * @param {string} [routeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDestinationsByRouteId(routeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RouteDestinationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDestinationsByRouteId(routeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Order route destinations
         * @param {RouteDestinationOrderRequest} routeDestinationOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderDestinations(routeDestinationOrderRequest: RouteDestinationOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RouteDestinationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderDestinations(routeDestinationOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove destination from address book
         * @param {RouteDestinationAddressRemoveRequest} routeDestinationAddressRemoveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFromAddressBook(routeDestinationAddressRemoveRequest: RouteDestinationAddressRemoveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteDestinationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFromAddressBook(routeDestinationAddressRemoveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update route destination
         * @param {RouteDestinationUpdateRequest} routeDestinationUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDestination(routeDestinationUpdateRequest: RouteDestinationUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteDestinationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDestination(routeDestinationUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DestinationApi - factory interface
 * @export
 */
export const DestinationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DestinationApiFp(configuration)
    return {
        /**
         * 
         * @summary Add destination to address book
         * @param {RouteDestinationAddressCreateRequest} routeDestinationAddressCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToAddressBook(routeDestinationAddressCreateRequest: RouteDestinationAddressCreateRequest, options?: any): AxiosPromise<RouteDestinationResponse> {
            return localVarFp.addToAddressBook(routeDestinationAddressCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add route destination
         * @param {RouteDestinationCreateRequest} routeDestinationCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDestination(routeDestinationCreateRequest: RouteDestinationCreateRequest, options?: any): AxiosPromise<RouteDestinationResponse> {
            return localVarFp.createDestination(routeDestinationCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete route destination
         * @param {RouteDestinationDeleteRequest} routeDestinationDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDestination(routeDestinationDeleteRequest: RouteDestinationDeleteRequest, options?: any): AxiosPromise<RouteDestinationResponse> {
            return localVarFp.deleteDestination(routeDestinationDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get route destination by id
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDestinationById(id?: string, options?: any): AxiosPromise<RouteDestinationResponse> {
            return localVarFp.getDestinationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get route destinations
         * @param {string} [routeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDestinationsByRouteId(routeId?: string, options?: any): AxiosPromise<Array<RouteDestinationResponse>> {
            return localVarFp.getDestinationsByRouteId(routeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Order route destinations
         * @param {RouteDestinationOrderRequest} routeDestinationOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderDestinations(routeDestinationOrderRequest: RouteDestinationOrderRequest, options?: any): AxiosPromise<Array<RouteDestinationResponse>> {
            return localVarFp.orderDestinations(routeDestinationOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove destination from address book
         * @param {RouteDestinationAddressRemoveRequest} routeDestinationAddressRemoveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromAddressBook(routeDestinationAddressRemoveRequest: RouteDestinationAddressRemoveRequest, options?: any): AxiosPromise<RouteDestinationResponse> {
            return localVarFp.removeFromAddressBook(routeDestinationAddressRemoveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update route destination
         * @param {RouteDestinationUpdateRequest} routeDestinationUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDestination(routeDestinationUpdateRequest: RouteDestinationUpdateRequest, options?: any): AxiosPromise<RouteDestinationResponse> {
            return localVarFp.updateDestination(routeDestinationUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DestinationApi - object-oriented interface
 * @export
 * @class DestinationApi
 * @extends {BaseAPI}
 */
export class DestinationApi extends BaseAPI {
    /**
     * 
     * @summary Add destination to address book
     * @param {RouteDestinationAddressCreateRequest} routeDestinationAddressCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationApi
     */
    public addToAddressBook(routeDestinationAddressCreateRequest: RouteDestinationAddressCreateRequest, options?: AxiosRequestConfig) {
        return DestinationApiFp(this.configuration).addToAddressBook(routeDestinationAddressCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add route destination
     * @param {RouteDestinationCreateRequest} routeDestinationCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationApi
     */
    public createDestination(routeDestinationCreateRequest: RouteDestinationCreateRequest, options?: AxiosRequestConfig) {
        return DestinationApiFp(this.configuration).createDestination(routeDestinationCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete route destination
     * @param {RouteDestinationDeleteRequest} routeDestinationDeleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationApi
     */
    public deleteDestination(routeDestinationDeleteRequest: RouteDestinationDeleteRequest, options?: AxiosRequestConfig) {
        return DestinationApiFp(this.configuration).deleteDestination(routeDestinationDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get route destination by id
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationApi
     */
    public getDestinationById(id?: string, options?: AxiosRequestConfig) {
        return DestinationApiFp(this.configuration).getDestinationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get route destinations
     * @param {string} [routeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationApi
     */
    public getDestinationsByRouteId(routeId?: string, options?: AxiosRequestConfig) {
        return DestinationApiFp(this.configuration).getDestinationsByRouteId(routeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Order route destinations
     * @param {RouteDestinationOrderRequest} routeDestinationOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationApi
     */
    public orderDestinations(routeDestinationOrderRequest: RouteDestinationOrderRequest, options?: AxiosRequestConfig) {
        return DestinationApiFp(this.configuration).orderDestinations(routeDestinationOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove destination from address book
     * @param {RouteDestinationAddressRemoveRequest} routeDestinationAddressRemoveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationApi
     */
    public removeFromAddressBook(routeDestinationAddressRemoveRequest: RouteDestinationAddressRemoveRequest, options?: AxiosRequestConfig) {
        return DestinationApiFp(this.configuration).removeFromAddressBook(routeDestinationAddressRemoveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update route destination
     * @param {RouteDestinationUpdateRequest} routeDestinationUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationApi
     */
    public updateDestination(routeDestinationUpdateRequest: RouteDestinationUpdateRequest, options?: AxiosRequestConfig) {
        return DestinationApiFp(this.configuration).updateDestination(routeDestinationUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
