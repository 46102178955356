import React from 'react';
import { useUserContext } from "../../../context/UserContextProvider";
import { Avatar } from "antd";

export interface UserAvatarViewProps {
  size?: number;
  onClick?: () => void;
}

const UserAvatarView = (props: UserAvatarViewProps): JSX.Element | null => {
  const { user } = useUserContext();
  if (!user) return null;
  const pictureUrl = user.pictureUrl;
  if (!pictureUrl) return null;

  return (
    <Avatar
      size={props.size || 68}
      src={pictureUrl}
      style={{ border: '1px solid black', cursor: 'pointer' }}
      onClick={props.onClick}
    />
  );
};

export default UserAvatarView;
