import React from "react";

const MIN_IN_HOUR = 60;
const MIN_IN_DAY = 24 * MIN_IN_HOUR;

export interface DaysHoursMinutes {
  days: number;
  hours: number;
  minutes: number;
}
export const minutesToDaysHoursMinutes = (mins: number): DaysHoursMinutes  => {
  let remainingMin = mins;

  const d = Math.floor(remainingMin < MIN_IN_DAY ? 0 : remainingMin / MIN_IN_DAY);
  remainingMin = remainingMin - d * MIN_IN_DAY;
  const h = Math.floor(remainingMin < MIN_IN_HOUR ? 0 : remainingMin / MIN_IN_HOUR);
  remainingMin = remainingMin - h * MIN_IN_HOUR;
  const m = remainingMin;

  return {
    days: d,
    hours: h,
    minutes: m
  };
}

export const formatMinutesAsDaysHoursMinutes = (mins: number): string => {
  const dhm = minutesToDaysHoursMinutes(mins);
  const parts = [];
  if (dhm.days > 0) {
    parts.push(`${dhm.days}d`);
  }

  if (dhm.hours > 0) {
    parts.push(`${dhm.hours}h`);
  }

  if (dhm.minutes > 0) {
    parts.push(`${dhm.minutes}m`);
  }

  return parts.join(" ");
}

export const daysHoursMinutesToMinutes = (dhm: DaysHoursMinutes): number => {
  return (dhm.days * MIN_IN_DAY) + (dhm.hours * MIN_IN_HOUR) + dhm.minutes;
}
