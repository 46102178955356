import React from 'react';
import styled from "styled-components";
import {Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle, faMicrosoft, faWaze} from "@fortawesome/free-brands-svg-icons";
import {getBingUrl, getGoogleUrl, getWazeUrl, launchNewTab} from "../../../../helpers/url";
import {RouteDestination} from "../../../../types/RouteDestination";

interface ExternalMapButtonsProps {
  destination: RouteDestination
}

const Container = styled.div`
  display: inline-block;
`;

const ExternalMapButtons = (props: ExternalMapButtonsProps): JSX.Element | null => {
  const {destination} = props;

  const openExternalUrl = (vendor: string) => {
    let url: string | null = null;

    switch (vendor) {
      case "waze": {
        url = getWazeUrl([destination]);
        break;
      }
      case "bing": {
        url = getBingUrl([destination]);
        break;
      }
      case "google": {
        url = getGoogleUrl([destination]);
        break;
      }
      default: {
        break;
      }
    }

    if (!url) {
      return;
    }

    launchNewTab(url);
  }

  return (
    <Container>
      <Button size={"small"} onClick={() => openExternalUrl('waze')} style={{marginRight: 3}}>
        <FontAwesomeIcon icon={faWaze} style={{color: '#666'}}/>
      </Button>
      <Button size={"small"} onClick={() => openExternalUrl('bing')} style={{marginRight: 3}}>
        <FontAwesomeIcon icon={faMicrosoft} style={{color: '#666'}}/>
      </Button>
      <Button size={"small"} onClick={() => openExternalUrl('google')} style={{marginRight: 3}}>
        <FontAwesomeIcon icon={faGoogle} style={{color: '#666'}}/>
      </Button>
    </Container>
  );
};

export default ExternalMapButtons;
