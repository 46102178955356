import React from 'react';
import {useMapContext} from '../../../../context/MapContextProvider';
import OsmLayer from './layers/OsmLayer';
import GoogleLayer from './layers/GoogleLayer';
import MapBoxLayer from './layers/MapBoxLayer';

const MapLayerViewController = (): JSX.Element => {
  const {
    engine,
  } = useMapContext();

  if (engine.indexOf('google') !== -1) {
    return (
      <GoogleLayer key={engine} type={engine.replace('google-', '')}/>
    );
  }

  if (engine.indexOf('mapbox') !== -1) {
    return (
      <MapBoxLayer key={engine} style={engine.replace('mapbox-', '')}/>
    );
  }

  return (
    <OsmLayer/>
  );
};

export default MapLayerViewController;
