import {Col, Row} from 'antd';
import React, {useState} from 'react';
import {MenuInfo} from 'rc-menu/lib/interface';
import UserProfile from './UserProfile';
import {useNavigate} from 'react-router-dom';
import styled from "styled-components";
import UserAvatarView from "./UserAvatarView";
import {useUserContext} from "../../../context/UserContextProvider";
import UserDetailsView from "./UserDetailsView";
import SidebarMenu from "./SidebarMenu";

interface HeaderProps {
}

const Container = styled.div`
  color: white;
  padding: 0;
  vertical-align: top;
  height: 50px;
  background: #272d37;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
`;

const Header = (props: HeaderProps): JSX.Element => {
  // const { logout }  = useAuth();
  const navigate = useNavigate();

  const {user} = useUserContext();
  const [detailsVisible, setDetailsVisible] = useState<boolean>(false);

  const items = [
    {label: <UserProfile/>, key: 'profile'},
    {label: 'Logout', key: 'logout'},
  ];

  const handleMenuClick = (info: MenuInfo): void => {
    switch (info.key) {
      case 'logout':
        // logout();
        break;
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Container style={{padding: 0}}>
      <Row>
        <Col flex={1}>
          <div style={{
            marginTop: 10,
            marginLeft: 15,
            paddingRight: 15,
            marginRight: 15,
            borderRight: "1px solid #666",
            fontSize: 24,
            display: "inline-block"
          }}>
            <SidebarMenu/>
          </div>

          <div style={{fontSize: 20, display: "inline-block", fontStyle: "italic", fontFamily: "Mochiy Pop One"}}>
            Route & Ride
          </div>
        </Col>

        <Col flex={"none"} style={{textAlign: "right", paddingTop: 7, paddingRight: 15}}>
          <UserAvatarView size={35} onClick={() => setDetailsVisible(!detailsVisible)}/>
          {
            detailsVisible && (
              <UserDetailsView/>
            )
          }
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
