import { Result } from 'antd';
import { Link } from 'react-router-dom';

const NotFoundPage = (): JSX.Element => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Page not found. Please check your URL."
      extra={
        <Link to={'/'}>Go to main page</Link>
      }
    />
  );
};

export default NotFoundPage;
