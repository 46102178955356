import React from 'react';
import {Marker} from "react-leaflet";
import L, {DragEndEvent, LeafletMouseEvent} from 'leaflet';
import {useMapContext} from "../../../../../context/MapContextProvider";
import {v4 as uuidv4} from "uuid";
import {useRouteContext} from "../../../../../context/RouteContextProvider";

interface MapLastClickViewProps {
}

const createIcon = () => {
  const className = "map-icon-number-last-click";
  const html = `<div class="last_click ${className}">+</div><div class="last_click map-icon-shadow"></div>`;
  return L.divIcon({
    className: "map-icon-last-click-location",
    iconAnchor: [12, 44],
    html: html
  });
};

const MapLastClickView = (props: MapLastClickViewProps): JSX.Element | null => {
  const { lastClickLocation, setLastClickLocation, setStreetViewSettings, streetViewSettings } = useMapContext();
  const { addDestination } = useRouteContext();

  const icon = createIcon();

  const eventHandlers = {
    dragend: (event: DragEndEvent) => {
      const location = event.target.getLatLng();
      setLastClickLocation(location);
      setStreetViewSettings({ location: location, enabled: streetViewSettings?.enabled === true })
    },
    click: (event: LeafletMouseEvent) => {
      const location = event.target.getLatLng();

      addDestination({
        id: uuidv4(),
        name: 'Dropped pin',
        location: location,
      });
    },
  };

  if (!lastClickLocation) {
    return null;
  }

  return (
    <Marker
      position={lastClickLocation}
      icon={icon}
      draggable
      eventHandlers={eventHandlers}
    />
  );
};

export default MapLastClickView;
