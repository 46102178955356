import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import {Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {DndProvider} from 'react-dnd';
import update from 'immutability-helper';
import {HTML5Backend} from "react-dnd-html5-backend";
import {CarOutlined} from "@ant-design/icons";
import useWindowSize from "../../../../hooks/useWindowSize";
import {Skeleton} from "antd/lib";
import {useRouteContext} from "../../../../context/RouteContextProvider";
import {RouteDestination} from "../../../../types/RouteDestination";
import {RouteSegment} from "../../../../types/RouteSegment";
import {useMapContext} from "../../../../context/MapContextProvider";
import {v4 as uuidv4} from "uuid";
import DestinationIndexView from "./views/DestinationIndexView";
import DestinationView from "./views/DestinationView";
import DestinationActionsView from "./views/DestinationActionsView";
import DraggableBodyRow from "./views/DraggableBodyRow";
import RouteSegmentView from "./views/RouteSegmentView";
import RouteIndexView from "./views/RouteIndexView";
import dayjs, {Dayjs} from "dayjs";
import {useLayoutContext} from "../../../../context/LayoutContextProvider";

export interface RouteStepsViewControllerProps {
}

interface RouteElement {
  id: string;
  index?: number;
  destination?: RouteDestination;
  routeSegment?: RouteSegment;
  departAt?: Dayjs | null;
  arriveAt?: Dayjs | null;
}

const Container = styled.div`
  overflow: auto;

  table > tbody > tr:hover > td {
    background: #e3e3e3 !important;
    border-radius: 0 !important;
  }
`;

const RouteTableViewController = (props: RouteStepsViewControllerProps): JSX.Element | null => {
  const {setCenterPoint} = useMapContext();

  const {expandBottomPane} = useLayoutContext();

  const {
    route,
    destinations,
    routeSegments,
    removeDestination,
    orderDestinations,
    selectedDestinationId,
    setSelectedDestinationId,
    selectedRouteSegmentId,
    setSelectedRouteSegmentId,
  } = useRouteContext();

  const size = useWindowSize();

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const selectDestination = (destination: RouteDestination) => {
    setCenterPoint(destination.location);
    setSelectedDestinationId(destination.id);
    expandBottomPane();
  };

  const selectRouteSegment = (routeSegment: RouteSegment) => {
    // setCenterPoint(destination.location);
    setSelectedDestinationId(routeSegment.id);
    expandBottomPane();
  };

  const routeElements: RouteElement[] = useMemo(() => {
    const elements: RouteElement[] = []

    let arriveAt: Dayjs = route.departAt || dayjs();
    let departAt = arriveAt;

    const legsByOriginId: Map<string, RouteSegment> = new Map();
    routeSegments.forEach(leg => {
      legsByOriginId.set(leg.originId, leg);
    });

    destinations.forEach((destination, index) => {
      departAt = destination.stayTimeInMin ? arriveAt.add(destination.stayTimeInMin, 'minute') : arriveAt;

      elements.push({
        id: uuidv4(),
        index: index,
        destination: destination,
        arriveAt: arriveAt,
        departAt: departAt
      });

      const legPathRoute = legsByOriginId.get(destination.id)
      if (legPathRoute) {
        arriveAt = departAt.add(legPathRoute.durationInSeconds, 'second');
        elements.push({
          id: uuidv4(),
          routeSegment: legPathRoute,
          arriveAt: arriveAt
        });
      }
    });

    return elements;
  }, [destinations, routeSegments, route]);

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex % 2 === 1) {
        return;
      }

      const destinationDragIndex = dragIndex / 2;
      const destinationDropIndex = hoverIndex / 2;
      // console.log('drag / drop', destinationDragIndex, destinationDropIndex);

      const dragRow = destinations[destinationDragIndex];
      orderDestinations(
        update(destinations, {
          $splice: [
            [destinationDragIndex, 1],
            [destinationDropIndex, 0, dragRow],
          ],
        }),
      );
    },
    [destinations],
  );

  const columns: ColumnsType<RouteElement> = [
    {
      dataIndex: 'label',
      render: (value: string, record: RouteElement) => {
        const destination = record.destination;

        if (destination) {
          return (
            <DestinationIndexView
              index={record.index || 0}
              total={destinations.length}
            />
          );
        }

        return <RouteIndexView index={record.index || 0}/>;
      },
      width: 10
    },
    {
      dataIndex: 'label',
      render: (text, record: RouteElement) => {
        const destination = record.destination;
        if (destination) {
          return <DestinationView
            destination={destination}
            onClick={() => setSelectedDestinationId(destination.id)}
            onDblClick={() => selectDestination(destination)}
            arriveAt={record.arriveAt}
            departAt={record.departAt}
          />
        }

        const routeSegment = record.routeSegment;
        if (routeSegment) {
          return <RouteSegmentView
            routeSegment={routeSegment}
            arriveAt={record.arriveAt}
            departAt={record.departAt}
            onClick={() => setSelectedRouteSegmentId(routeSegment.id)}
            onDblClick={() => selectRouteSegment(routeSegment)}
          />;
        }

        return <div></div>;
      },
    },
    {
      dataIndex: 'label',
      render: (text, record: RouteElement) => {
        const destination = record.destination;
        if (destination) {
          return (
            <DestinationActionsView
              onRemove={() => removeDestination(destination.id)}
            />
          );
        }

        return <div></div>;
      },
    }
  ];

  if (!size.height) {
    return <Skeleton active/>
  }

  if (destinations.length === 0) {
    return (
      <div style={{padding: 20, fontSize: 13, textAlign: "center"}}>
        <CarOutlined style={{fontSize: 34, color: "#ccc", marginBottom: 20, marginTop: 30}}/> <br/>
        Add your first destination by entering an address or clicking on the map.
      </div>
    )
  }

  const getRowClassName = (record: RouteElement): string => {
    const classNames: string[] = [];
    if (record.destination) {
      classNames.push("row-kind-details");
    } else {
      classNames.push("row-kind-routes");
    }

    if (record.destination?.id === selectedDestinationId) {
      classNames.push('table-row-selected');
    } else if (record.routeSegment?.id === selectedRouteSegmentId) {
      classNames.push('table-row-selected');
    } else {
      classNames.push('table-row-light');
    }

    return classNames.join(" ");
  }

  return (
    <Container style={{width: '100%', height: size.height - 270, overflow: 'scroll'}}>
      <DndProvider backend={HTML5Backend}>
        <Table
          rowClassName={(record, index) => {
            return getRowClassName(record);
          }}
          columns={columns}
          dataSource={routeElements}
          components={components}
          showHeader={false}
          rowKey={'id'}
          size={'small'}
          onRow={(_, index) => {
            const attr = {
              index,
              moveRow,
            };
            return attr as React.HTMLAttributes<any>;
          }}
          pagination={false}
        />
      </DndProvider>
    </Container>
  );
};

export default RouteTableViewController;
