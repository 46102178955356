import config from "./config";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth, GoogleAuthProvider, FacebookAuthProvider} from "firebase/auth";

export const app = initializeApp(config);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);


export const googleAuthProvider = new GoogleAuthProvider();

export const facebookAuthProvider = new FacebookAuthProvider();
