/* eslint-disable import/named */
import React from 'react';
import {TileLayer} from 'react-leaflet';

// to test various features of OSM, use the OSM direct url below:
const OPEN_STREET_MAP_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

const OsmLayer = (): JSX.Element => {
  return (
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">Open Street Maps</a>'
      url={OPEN_STREET_MAP_URL}
    />
  );
};

export default OsmLayer;
