import React, {useContext, useMemo, useState} from "react";
import MapContext, {defaultLocation, defaultZoomLevel, MapContextType} from "./MapContext";
import {MapContextMenu} from "../pages/map/types/MapContextMenu";
import {LatLng, LatLngBounds} from "leaflet";
import {StreetViewSettings} from "../pages/map/types/StreetViewSettings";

export interface MapContextProviderOptions {
  debug?: boolean;
}

export interface MapContextProviderProps {
  opts?: MapContextProviderOptions;
  children: JSX.Element;
}

const DEFAULT_MAP_ENGINE = "mapbox-streets-v12"; // osm

export const MapContextProvider: React.FC<MapContextProviderProps> = (props) => {
  const [zoomLevel, setZoomLevel] = useState<number>(defaultZoomLevel);
  const [bounds, setBounds] = useState<LatLngBounds | null>(null);
  const [currentUserLocation, setCurrentUserLocation] = useState<LatLng | null>(null);
  const [engine, setEngine] = useState<string>(DEFAULT_MAP_ENGINE);
  const [contextMenu, setContextMenu] = useState<MapContextMenu | null>(null);
  const [centerPoint, setCenterPoint] = useState<LatLng | null>(null);
  const [streetViewSettings, setStreetViewSettings] = useState<StreetViewSettings | null>(null);
  const [lastClickLocation, setLastClickLocation] = useState<LatLng | null>(null);
  const [editRouteSegmentEnabled, setEditRouteSegmentEnabled] = useState<boolean>(false);

  const showContextMenu = (menu: MapContextMenu) => {
    setContextMenu(menu);
  };

  const hideContextMenu = () => {
    setContextMenu(null);
  };

  const zoomIn = () => {
    if (zoomLevel < 18) {
      setZoomLevel(zoomLevel + 1);
    }
  };

  const zoomOut = () => {
    if (zoomLevel > 0) {
      setZoomLevel(zoomLevel - 1);
    }
  };

  const toggleStreetViewSettings = (location?: LatLng) => {
    if (streetViewSettings) {
      setStreetViewSettings({...streetViewSettings, ...{enabled: !streetViewSettings.enabled}});
    } else {
      setStreetViewSettings({location: location || defaultLocation, enabled: true});
    }
  };

  const value = useMemo<MapContextType>(() => {
      if (props.opts?.debug) {
        console.log('Updating map context');
      }

      return {
        bounds,
        setBounds,
        zoomLevel,
        setZoomLevel,
        zoomIn,
        zoomOut,
        currentUserLocation,
        setCurrentUserLocation,
        centerPoint,
        setCenterPoint,
        engine,
        setEngine,
        contextMenu,
        showContextMenu,
        hideContextMenu,
        streetViewSettings,
        setStreetViewSettings,
        toggleStreetViewSettings,
        lastClickLocation,
        setLastClickLocation,
        editRouteSegmentEnabled,
        setEditRouteSegmentEnabled
      };
    }, [
      zoomLevel, bounds, centerPoint,
      engine, contextMenu, currentUserLocation,
      lastClickLocation, streetViewSettings,
      editRouteSegmentEnabled
    ]
  );

  return (
    <MapContext.Provider value={value}>
      {props.children}
    </MapContext.Provider>
  );
};

export const useMapContext: () => MapContextType = () => {
  return useContext(MapContext);
};
