import React from 'react';
import {Marker} from "react-leaflet";
import L, {DragEndEvent, LatLng} from 'leaflet';
import {RouteDestination} from "../../../../../types/RouteDestination";
import {useLayoutContext} from "../../../../../context/LayoutContextProvider";

interface DestinationViewProps {
  destination: RouteDestination;
  index: number;
  total: number;
  selected: boolean;
  onMoved: (location: LatLng) => void;
  onClicked: () => void;
}

const getIconClassName = (index: number, total: number): string => {
  if (index === 0) {
    return "map-icon-start";
  }
  if (index === total - 1) {
    return "map-icon-end";
  }
  return "map-icon-waypoint";
};

const createIcon = (destination: RouteDestination, index: number, total: number, selected: boolean) => {
  const className = selected ? "map-icon-number-selected" : "map-icon-number";
  const html = `<div class="destination_${destination.id} ${className}">${index + 1}</div><div class="destination_${destination.id} map-icon-shadow"></div>`;
  return L.divIcon({
    className: getIconClassName(index, total),
    iconAnchor: [12, 44],
    html: html
  });
};

const MapRouteDestinationView = (props: DestinationViewProps): JSX.Element | null => {
  const {destination, index, total, selected} = props;
  const {expandBottomPane} = useLayoutContext();

  const icon = createIcon(destination, index, total, selected);

  const eventHandlers = {
    click: () => {
      props.onClicked();
    },
    dragend: (event: DragEndEvent) => {
      props.onMoved(event.target.getLatLng());
    },
    dblclick: () => {
      expandBottomPane();
    }
  };

  return (
    <Marker
      key={`destination_${destination.id}`}
      position={destination.location}
      icon={icon}
      draggable={true}
      eventHandlers={eventHandlers}
    />
  );
};

export default MapRouteDestinationView;
