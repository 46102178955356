import React from 'react';
import styled from "styled-components";
import {useUserContext} from "../../../context/UserContextProvider";
import UserAvatarView from "./UserAvatarView";
import {Button, Col, Row} from "antd";

const Container = styled.div`
  position: absolute;
  padding-top: 20px;
  top: 55px;
  right: 10px;
  z-index: 950;
  background: white;
  text-align: center;
  width: 300px;
  border: 1px solid #eee;
  min-height: 120px;
  border-radius: 10px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
`;

const Text = styled.div`
  font-family: Arial, serif;
  font-size: 14px;
  font-style: normal;
  color: #444;
  margin-top: 5px;
`;

const UserDetailsView = (): JSX.Element => {
  const {user, logout} = useUserContext();

  return (
    <Container>
      <Row>
        <Col span={24}>
          <UserAvatarView/>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div>
            <Text>{user?.name || ''}</Text>
          </div>
          <div>
            <Text>{user?.email || ''}</Text>
          </div>
        </Col>
      </Row>
      <div style={{marginTop: 30, paddingTop: 10, borderTop: '1px solid #eee', marginBottom: 10}}>
        <Button onClick={logout}>
          Sign out
        </Button>
      </div>
    </Container>
  );
};

export default UserDetailsView;
