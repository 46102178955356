import React, {useContext, useState} from "react";
import { useHotkeys } from "react-hotkeys-hook";
import LayoutContext, {LayoutContextType} from "./LayoutContext";

export interface LayoutContextProviderOptions {
  debug?: boolean;
}

export interface LayoutContextProviderProps {
  opts?: LayoutContextProviderOptions;
  children: JSX.Element;
}

export const LayoutContextProvider: React.FC<LayoutContextProviderProps> = (props) => {
  const [leftPaneCollapsed, setLeftPaneCollapsed] = useState<boolean>(true);
  const [bottomPaneCollapsed, setBottomPaneCollapsed] = useState<boolean>(true);
  const [selectedTool, setSelectedTool] = useState<string | null>(null);

  useHotkeys('ctrl+shift+a', () => {
    setSelectedTool("addresses");
  }, [ selectedTool ]);

  useHotkeys('ctrl+shift+r', () => {
    setSelectedTool("routes");
  }, [ selectedTool ]);

  const expandLeftPane = () => {
    setLeftPaneCollapsed(true);
  }

  const collapseLeftPane = () => {
    setLeftPaneCollapsed(false);
  }

  const toggleLeftPane = () => {
    setLeftPaneCollapsed(!leftPaneCollapsed);
  }

  const expandBottomPane = () => {
    setBottomPaneCollapsed(true);
  }

  const collapseBottomPane = () => {
    setBottomPaneCollapsed(false);
  }

  const toggleBottomPane = () => {
    setBottomPaneCollapsed(!bottomPaneCollapsed);
  }

  const value = {
    leftPaneCollapsed,
    toggleLeftPane,
    collapseLeftPane,
    bottomPaneCollapsed,
    toggleBottomPane,
    expandLeftPane,
    expandBottomPane,
    collapseBottomPane,
    selectedTool,
    setSelectedTool,
  };

  return (
    <LayoutContext.Provider value={value}>
      {props.children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext: () => LayoutContextType = () => {
  return useContext(LayoutContext);
};
