export default {
  apiKey: "AIzaSyBIxWvn2JN0d36XLqdSMwXuQABM9eWd5gA",
  authDomain: "routenride-2b054.firebaseapp.com",
  projectId: "routenride-2b054",
  storageBucket: "routenride-2b054.appspot.com",
  messagingSenderId: "530208920602",
  appId: "1:530208920602:web:f7044c6ba75b13a7486da3",
  measurementId: "G-6KLFZ2E34S",
  facebookRedirectUrl: "routenride-2b054.firebaseapp.com/__/auth/handler"
};
