/* tslint:disable */
/* eslint-disable */
/**
 * Route & Ride API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddressCreateRequest } from '../model';
// @ts-ignore
import { AddressDeleteRequest } from '../model';
// @ts-ignore
import { AddressResponse } from '../model';
// @ts-ignore
import { AddressUpdateRequest } from '../model';
// @ts-ignore
import { PagedCollectionAddressResponse } from '../model';
/**
 * AddressApi - axios parameter creator
 * @export
 */
export const AddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create address
         * @param {AddressCreateRequest} addressCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddress: async (addressCreateRequest: AddressCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressCreateRequest' is not null or undefined
            assertParamExists('createAddress', 'addressCreateRequest', addressCreateRequest)
            const localVarPath = `/v1/addresses/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addressCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete address
         * @param {AddressDeleteRequest} addressDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddress: async (addressDeleteRequest: AddressDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressDeleteRequest' is not null or undefined
            assertParamExists('deleteAddress', 'addressDeleteRequest', addressDeleteRequest)
            const localVarPath = `/v1/addresses/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addressDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Filter addresses
         * @param {string} [search] 
         * @param {string} [order] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterAddresses: async (search?: string, order?: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/addresses/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get address by id
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressById: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/addresses/by-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update address
         * @param {AddressUpdateRequest} addressUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddress: async (addressUpdateRequest: AddressUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressUpdateRequest' is not null or undefined
            assertParamExists('updateAddress', 'addressUpdateRequest', addressUpdateRequest)
            const localVarPath = `/v1/addresses/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addressUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressApi - functional programming interface
 * @export
 */
export const AddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create address
         * @param {AddressCreateRequest} addressCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAddress(addressCreateRequest: AddressCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAddress(addressCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete address
         * @param {AddressDeleteRequest} addressDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAddress(addressDeleteRequest: AddressDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAddress(addressDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Filter addresses
         * @param {string} [search] 
         * @param {string} [order] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterAddresses(search?: string, order?: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedCollectionAddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterAddresses(search, order, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get address by id
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressById(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update address
         * @param {AddressUpdateRequest} addressUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAddress(addressUpdateRequest: AddressUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAddress(addressUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddressApi - factory interface
 * @export
 */
export const AddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressApiFp(configuration)
    return {
        /**
         * 
         * @summary Create address
         * @param {AddressCreateRequest} addressCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddress(addressCreateRequest: AddressCreateRequest, options?: any): AxiosPromise<AddressResponse> {
            return localVarFp.createAddress(addressCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete address
         * @param {AddressDeleteRequest} addressDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddress(addressDeleteRequest: AddressDeleteRequest, options?: any): AxiosPromise<AddressResponse> {
            return localVarFp.deleteAddress(addressDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Filter addresses
         * @param {string} [search] 
         * @param {string} [order] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterAddresses(search?: string, order?: string, offset?: number, limit?: number, options?: any): AxiosPromise<PagedCollectionAddressResponse> {
            return localVarFp.filterAddresses(search, order, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get address by id
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressById(id?: string, options?: any): AxiosPromise<AddressResponse> {
            return localVarFp.getAddressById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update address
         * @param {AddressUpdateRequest} addressUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddress(addressUpdateRequest: AddressUpdateRequest, options?: any): AxiosPromise<AddressResponse> {
            return localVarFp.updateAddress(addressUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
export class AddressApi extends BaseAPI {
    /**
     * 
     * @summary Create address
     * @param {AddressCreateRequest} addressCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public createAddress(addressCreateRequest: AddressCreateRequest, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).createAddress(addressCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete address
     * @param {AddressDeleteRequest} addressDeleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public deleteAddress(addressDeleteRequest: AddressDeleteRequest, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).deleteAddress(addressDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Filter addresses
     * @param {string} [search] 
     * @param {string} [order] 
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public filterAddresses(search?: string, order?: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).filterAddresses(search, order, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get address by id
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public getAddressById(id?: string, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).getAddressById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update address
     * @param {AddressUpdateRequest} addressUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public updateAddress(addressUpdateRequest: AddressUpdateRequest, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).updateAddress(addressUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
