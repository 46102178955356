import React, {useState} from 'react';
import {AutoComplete, Input} from "antd";
import styled from "styled-components";
import type {SelectProps} from 'antd/es/select';
import {LoaderOptions} from '@googlemaps/js-api-loader';
import {useGoogleContext} from "../../../../../context/GoogleContextProvider";
import {LatLng} from "leaflet";
import {useMapContext} from "../../../../../context/MapContextProvider";

const Container = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
`;

export interface AutocompletionRequest {
  bounds?: [LatLng, LatLng];
  componentRestrictions?: { country: string | string[] };
  location?: LatLng;
  offset?: number;
  radius?: number;
  types?: string[];
}

export interface GooglePlacesAutocompleteProps {
  apiKey?: string;
  apiOptions?: Partial<LoaderOptions>;
  autocompletionRequest?: AutocompletionRequest;
  debounce?: number;
  minLengthAutocomplete?: number;
  onLoadFailed?: (error: Error) => void;
  withSessionToken?: boolean;
}

export interface MapAddressBarProps {
  width: number;
}

const buildAutocompletionRequest = (
  autocompletionRequest: AutocompletionRequest,
  input: string,
  sessionToken?: google.maps.places.AutocompleteSessionToken,
): google.maps.places.AutocompletionRequest => {
  const {bounds, location, ...rest} = autocompletionRequest;

  const res: google.maps.places.AutocompletionRequest = {
    input,
    ...rest,
  };

  if (sessionToken) {
    res.sessionToken = sessionToken;
  }

  if (bounds) {
    res.bounds = new google.maps.LatLngBounds(...bounds);
  }

  if (location) {
    res.location = new google.maps.LatLng(location);
  }

  return res;
};

const getLocation = (result: google.maps.places.PlaceResult | null): LatLng | null => {
  if (!result) {
    return null;
  }

  const lat = result.geometry?.location?.lat();
  const lng = result.geometry?.location?.lng();

  if (!lat || !lng) {
    return null;
  }

  return new LatLng(lat, lng)
};

const MapAddressBar = (props: MapAddressBarProps): JSX.Element | null => {
  const {width} = props;

  const [options, setOptions] = useState<SelectProps<string>[]>([]);
  const [defaultAutocompletionRequest, setDefaultAutocompletionRequest] = useState<AutocompletionRequest>({});
  const [value, setValue] = useState<string>("");

  const {getPlaceDetails} = useGoogleContext();

  const {
    setCenterPoint,
    setStreetViewSettings,
    streetViewSettings,
    setLastClickLocation
  } = useMapContext();

  const {
    placesService,
    placesAutoCompleteService,
    placesAutoCompleteSessionToken
  } = useGoogleContext();

  if (!placesAutoCompleteService || !placesService) {
    return null;
  }

  const handleSearch = (value: string) => {
    setValue(value);

    if (value.length < 3) {
      return;
    }

    placesAutoCompleteService.getPlacePredictions(
      buildAutocompletionRequest(
        defaultAutocompletionRequest,
        value,
        placesAutoCompleteSessionToken,
      ), (suggestions) => {
        const opts = (suggestions || []).map(suggestion => ({
          key: suggestion.place_id,
          label: suggestion.description,
          value: suggestion.place_id
        }));
        setOptions(opts);
      },
    );
  };

  const onSelect = (value: string) => {
    setValue("");

    getPlaceDetails(value).then((result: google.maps.places.PlaceResult) => {
      const location = getLocation(result);

      if (location) {
        setLastClickLocation(location);
        setCenterPoint(location);
        setStreetViewSettings({location: location, enabled: streetViewSettings?.enabled === true});
      }
    });
  };

  return (
    <Container>
      <AutoComplete
        dropdownMatchSelectWidth={252}
        style={{width: width}}
        options={options}
        onSelect={onSelect}
        onSearch={handleSearch}
        value={value}
      >
        <Input size="large" placeholder="Enter address"/>
      </AutoComplete>
    </Container>
  );
};

export default MapAddressBar;
