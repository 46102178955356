import React from 'react';
import {useRouteContext} from "../../../context/RouteContextProvider";
import {CarOutlined} from "@ant-design/icons";
import DestinationDetailsViewController from "./details/DestinationDetailsViewController";
import RouteSegmentDetailsViewController from "./details/RouteSegmentDetailsViewController";
import {useMapContext} from "../../../context/MapContextProvider";
import LastClickViewController from "./details/LastClickViewController";

const DetailsViewController = (): JSX.Element | null => {
  const {selectedDestination, selectedRouteSegment} = useRouteContext();
  const {lastClickLocation} = useMapContext();

  if (selectedDestination) {
    return <DestinationDetailsViewController/>
  }

  if (selectedRouteSegment) {
    return <RouteSegmentDetailsViewController/>
  }

  if (lastClickLocation) {
    return <LastClickViewController/>
  }

  return (
    <div style={{textAlign: "center", padding: 20, paddingTop: 120, color: "#ccc"}}>
      <div style={{fontSize: 60, marginBottom: 10}}>
        <CarOutlined/>
      </div>
      Select a destination or a route segment from the list on the left.
    </div>
  );
};

export default DetailsViewController;
