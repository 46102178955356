import React from 'react';
import styled from "styled-components";
import {Dimensions} from "../../types/Dimensions";
import MapAddressBar from "./menu/MapAddressBar";
import MapLocateButton from "./menu/MapLocateButton";

const MENU_BAR_WIDTH = 460;

const Container = styled.div`
  position: absolute;
  width: ${MENU_BAR_WIDTH}px;
  top: 20px;
  z-index: 950;
  border-radius: 10px;
`;

export interface MapMenuBarViewControllerProps {
  dimensions?: Dimensions;
}

const MapMenuBarViewController = (props: MapMenuBarViewControllerProps): JSX.Element | null => {
  const {dimensions} = props;

  const width = dimensions?.width;

  if (!width) {
    return null;
  }

  return (
    <Container style={{left: parseInt(width) / 2 - MENU_BAR_WIDTH / 2}}>
      <div style={{display: 'inline-block', float: 'right'}}>
        <MapLocateButton/>
        {/*<MapUploadButton />*/}
      </div>
      <div style={{display: 'inline-block'}}>
        <MapAddressBar width={400}/>
      </div>
    </Container>
  );
};

export default MapMenuBarViewController;
