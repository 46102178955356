import React, {CSSProperties} from 'react';
import {Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard, faGlobe, faLocationDot, faPhone} from "@fortawesome/free-solid-svg-icons";
import {ColumnsType} from "antd/es/table";
import styled from "styled-components";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {RouteDestination} from "../../../../types/RouteDestination";

interface DestinationInfo {
  icon: JSX.Element,
  label: string,
  value?: JSX.Element | string | null,
  style?: CSSProperties,
}

interface DestinationDetailsTableViewProps {
  destination: RouteDestination
}

const MissingData = styled.div`
  color: #ccc;
`;

const DestinationDetailsTableView = (props: DestinationDetailsTableViewProps): JSX.Element | null => {
  const {destination} = props;

  const infoColumns: ColumnsType<DestinationInfo> = [
    {
      dataIndex: "icon",
    },
    {
      dataIndex: "value",
      render: (value: string, record: DestinationInfo, index: number) => {
        return (
          <div style={record.style}>{value}</div>
        );
      }
    }
  ];

  const infoData: DestinationInfo[] = [
    {
      icon: <FontAwesomeIcon icon={faAddressCard}/>,
      label: "Address",
      value: destination.address,
      style: {color: "#888", fontSize: 11},
    },
    {
      icon: <FontAwesomeIcon icon={faLocationDot}/>,
      label: "Location",
      value: `${destination.location.lat}, ${destination.location.lng}`,
      style: {color: "#888", fontSize: 11},
    },
    {
      icon: <FontAwesomeIcon icon={faPhone}/>,
      label: "Phone",
      value: destination.phone ? (
        <a href={destination.phone}>{destination.phone}</a>
      ) : (
        <MissingData>n/a</MissingData>
      ),
      style: {color: "#888", fontSize: 11},
    },
    {
      icon: <FontAwesomeIcon icon={faGlobe}/>,
      label: "URL",
      value: destination.url ? (
        <div style={{maxWidth: 200}}>
          <a href={destination.url}>{destination.url}</a>
        </div>
      ) : (
        <MissingData>n/a</MissingData>
      ),
      style: {color: "#888", fontSize: 11},
    },
    {
      icon: <FontAwesomeIcon icon={faClock}/>,
      label: "Business Hours",
      value: (<MissingData>n/a</MissingData>),
      style: {color: "#888", fontSize: 11},
    }
  ];

  return (
    <Table
      showHeader={false}
      size={"small"}
      columns={infoColumns}
      dataSource={infoData} rowKey={"label"}
      rowClassName={"table-row-align-top"}
      pagination={false}
    />
  );
};

export default DestinationDetailsTableView;
