import React, {FunctionComponent} from 'react';
import {Route, Routes} from 'react-router-dom';
import MapPage from "./pages/map";
import NotFoundPage from "./pages/default/NotFoundPage";

const AppRoutes: FunctionComponent = () => (
  <Routes>
    <Route path="/" element={<MapPage/>}/>
    <Route path="/routes/:routeId" element={<MapPage/>}/>
    <Route path="*" element={<NotFoundPage/>}/>
  </Routes>
);

export default AppRoutes;
