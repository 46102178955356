import {createContext} from "react";
import {
  AddressCreateRequest,
  AddressDeleteRequest,
  AddressResponse,
  AddressUpdateRequest,
  PagedCollectionAddressResponse
} from "../api/client";

export type AddressBookContextType = {
  filterAddresses: (search: string, order: string, offset: number, limit: number) => Promise<PagedCollectionAddressResponse>;
  createAddress: (request: AddressCreateRequest) => Promise<AddressResponse>;
  updateAddress: (request: AddressUpdateRequest) => Promise<AddressResponse>;
  deleteAddress: (request: AddressDeleteRequest) => Promise<AddressResponse>;
};

export const AddressBookDefaultContext: AddressBookContextType = {
  filterAddresses: (search: string, order: string, offset: number, limit: number) => {
    return new Promise((success, failure) => {
      failure("Not implemented");
    });
  },
  createAddress: (request: AddressCreateRequest) => {
    return new Promise((success, failure) => {
      failure("Not implemented");
    });
  },
  updateAddress: (request: AddressUpdateRequest) => {
    return new Promise((success, failure) => {
      failure("Not implemented");
    });
  },
  deleteAddress: (request: AddressDeleteRequest) => {
    return new Promise((success, failure) => {
      failure("Not implemented");
    });
  },
};

export default createContext<AddressBookContextType>(AddressBookDefaultContext);
