import React, {useEffect, useState} from 'react';
import {Input, Modal} from "antd";
import TextArea from "antd/es/input/TextArea";
import styled from "styled-components";
import {AddressResponse} from "../../../api/client";
import {useAddressBookContext} from "../../../context/AddressBookContextProvider";

interface AddressDetailsModalProps {
  address: AddressResponse;
  open: boolean;
  onAddressUpdated: () => void;
  onCancel: () => void;
}

const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #888;
`;

const AddressDetailsModal = (props: AddressDetailsModalProps): JSX.Element => {
  const {address} = props;

  const [name, setName] = useState<string>(address.name || '');
  const [notes, setNotes] = useState<string>(address.notes || '');
  const [phone, setPhone] = useState<string>(address.phone || '');
  const [url, setUrl] = useState<string>(address.url || '');
  const [error, setError] = useState<string | null>(null);

  const {updateAddress} = useAddressBookContext();

  useEffect(() => {
    setName(address.name || '');
    setNotes(address.notes || '');
    setPhone(address.phone || '')
    setUrl(address.url || '')
  }, [address]);

  const handleOk = () => {
    updateAddress({
      id: address.id,
      name: name,
      notes: notes,
      phone: phone,
      url: url
    }).then(() => {
      props.onAddressUpdated();
    }).catch(() => {
      setError('Failed to update address.');
    });
  }

  return (
    <Modal title="Edit Address Details" open={props.open} onOk={handleOk} okText={"Update Address"}
           onCancel={() => props.onCancel()}>

      <div>
        <div>
          {address.address}
        </div>
        <div style={{fontSize: 12, color: '#666'}}>
          {address.location?.lat}, {address.location?.lng}
        </div>
      </div>

      <Label>
        Name
      </Label>
      <Input
        value={name}
        placeholder={'Address name'}
        style={{marginBottom: 5}}
        onChange={(event) => setName(event.target.value)}
      />

      <Label>
        Phone
      </Label>
      <Input
        value={phone}
        placeholder={'Phone'}
        style={{marginBottom: 5}}
        onChange={(event) => setPhone(event.target.value)}
      />

      <Label>
        URL
      </Label>
      <Input
        value={url}
        placeholder={'Address web URL'}
        style={{marginBottom: 5}}
        onChange={(event) => setUrl(event.target.value)}
      />

      <Label>
        Notes
      </Label>
      <TextArea
        value={notes}
        placeholder={'Notes'}
        onChange={(event) => setNotes(event.target.value)}
        style={{marginBottom: 5, height: 60}}
      />

    </Modal>
  );
};

export default AddressDetailsModal;
