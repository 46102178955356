import React from "react";
import styled from "styled-components";
import {Button, Modal} from "antd";
import {signInWithPopup} from "firebase/auth";
import {auth, googleAuthProvider} from '../../../firebase';
import {GoogleOutlined} from "@ant-design/icons";
import {UserCredential} from "@firebase/auth";

const Container = styled.div`
`;

interface LoginWithGoogleProps {
  onUserLoggedInWithGoogle: (creds: UserCredential) => void;
}

const LoginWithGoogle = (props: LoginWithGoogleProps): JSX.Element => {

  const signInWithGoogle = async () => {
    try {
      const response = await signInWithPopup(auth, googleAuthProvider);
      props.onUserLoggedInWithGoogle(response);
    } catch (err: any) {
      if (err.code === 'auth/account-exists-with-different-credential') {
        Modal.error({
          title: 'Google Login',
          content: 'Your Google email is already associated with an email based account. Please login with your email and password instead.',
        });
      } else {
        console.error(err);
      }
    }
  };

  return (
    <Container>
      <Button
        icon={(<GoogleOutlined/>)}
        onClick={signInWithGoogle}
        style={{
          width: 180,
          textAlign: "left"
        }}>
        Sign in With Google
      </Button>
    </Container>
  );
};

export default LoginWithGoogle;
