import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Button, Card, Col, message, Row, Tag, Tooltip} from "antd";
import DestinationDetailsTableView from "./DestinationDetailsTableView";
import DestinationFormView from "./DestinationFormView";
import DestinationPhotosView from "./DestinationPhotosView";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useMapContext} from "../../../../context/MapContextProvider";
import {useGoogleContext} from "../../../../context/GoogleContextProvider";
import {RouteDestination} from "../../../../types/RouteDestination";
import {v4 as uuidv4} from 'uuid';
import {faAddressBook} from "@fortawesome/free-regular-svg-icons";
import ExternalMapButtons from "./ExternalMapButtons";
import {useAddressBookContext} from "../../../../context/AddressBookContextProvider";

const Container = styled.div`

`;

const Header = styled.div`
  height: 40px;
  background: #f0f0f0;
  padding: 0 10px;
  border-bottom: 1px solid #ddd;
`;

const Body = styled.div`
  padding: 10px;
`;

const DestinationDetailsViewController = (): JSX.Element | null => {
  const {lastClickLocation} = useMapContext();
  const {getAddressByLocation, getPlaceDetails} = useGoogleContext();
  const [lastClickDestination, setLastClickDestination] = useState<RouteDestination | null>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const {createAddress} = useAddressBookContext();

  useEffect(() => {
    if (!lastClickLocation) {
      setLastClickDestination(null);
      return;
    }

    getAddressByLocation(lastClickLocation).then((result: google.maps.GeocoderResult) => {
      getPlaceDetails(result.place_id).then((result) => {
        setLastClickDestination({
          id: uuidv4(),
          name: result.formatted_address || '',
          placeId: result.place_id || null,
          location: lastClickLocation,
          address: result.formatted_address,
          phone: result.formatted_phone_number,
          url: result.url,
          photos: result.photos?.map((it) => {
            return {
              id: uuidv4(),
              url: it.getUrl()
            }
          })
        });
      }).catch(() => {
        setLastClickDestination({
          id: uuidv4(),
          name: `${lastClickLocation.lat}, ${lastClickLocation.lng}`,
          location: lastClickLocation,
        });
      });
    }).catch(() => {
      setLastClickDestination({
        id: uuidv4(),
        name: `${lastClickLocation.lat}, ${lastClickLocation.lng}`,
        location: lastClickLocation,
      });
    });
  }, [lastClickLocation]);

  if (!lastClickDestination) {
    return null;
  }

  const addToAddressBook = () => {
    createAddress(
      {
        name: lastClickDestination.name,
        location: {
          lat: lastClickDestination.location.lat,
          lng: lastClickDestination.location.lng,
        },
        googlePlaceId: lastClickDestination.placeId || undefined,
        address: lastClickDestination.address || undefined,
        phone: lastClickDestination.phone || undefined,
        url: lastClickDestination.url || undefined,
      }
    ).then(() => {
      messageApi.info('Address book has been updated.');
    });
  };

  return (
    <Container>
      {contextHolder}
      <Header>
        <Row>
          <Col flex={1} style={{paddingTop: 10}}>
            <Tag color={"yellow"}>{lastClickDestination?.address}</Tag>
          </Col>
          <Col flex={"none"} style={{textAlign: "right", paddingTop: 6}}>
            <Button size={"small"} onClick={addToAddressBook} style={{marginRight: 15, width: 30}}>
              <Tooltip placement="top" title={'Add to the address book'}>
                <FontAwesomeIcon icon={faAddressBook} style={{color: '#666'}}/>
              </Tooltip>
            </Button>

            <ExternalMapButtons destination={lastClickDestination}/>
          </Col>
        </Row>
      </Header>
      <Body>
        <Row>
          <Col flex={"auto"}>
            <Row>
              <Col span={14} style={{paddingRight: 10}}>
                <Card bodyStyle={{height: 270, overflow: "auto", padding: 10}}>
                  <DestinationFormView destination={lastClickDestination} lastClick={true}/>
                </Card>
              </Col>
              <Col span={10}>
                <Card bodyStyle={{padding: 0, height: 270, overflow: "auto"}}>
                  <DestinationDetailsTableView destination={lastClickDestination}/>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col flex={"none"} style={{paddingLeft: 10}}>
            <DestinationPhotosView destination={lastClickDestination}/>
          </Col>
        </Row>
      </Body>
    </Container>
  );
};

export default DestinationDetailsViewController;
