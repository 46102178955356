import React, {useEffect, useState} from "react";
import "leaflet/dist/leaflet.css";
import useWindowSize from "../../hooks/useWindowSize";
import {Skeleton} from "antd";
import {HandlerProps, ReflexContainer, ReflexElement, ReflexSplitter} from "react-reflex";
import 'react-reflex/styles.css'
import MapViewController from "./components/MapViewController";
import RouteViewController from "./components/RouteViewController";
import MapContextMenu from "./components/map/MapContextMenu";
import DetailsViewController from "./components/DetailsViewController";
import {useRouteContext} from "../../context/RouteContextProvider";
import {useLayoutContext} from "../../context/LayoutContextProvider";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import RoutesList from "../routes";
import AddressBook from "../addresses";
import {useMapContext} from "../../context/MapContextProvider";
import {useHotkeys} from "react-hotkeys-hook";

const PANEL_COLLAPSED_SIZE = 0;
const PANEL_LEFT_MAX_SIZE = 350;
const PANEL_BOTTOM_MAX_SIZE = 340;
const PANEL_ANIMATION_STEP = 100;

const SPLITTER_STYLE = {border: '1px solid #eee'};

const Container = styled.div`

`;

const MapLayout = (): JSX.Element => {
  const location = useLocation();

  const size = useWindowSize();
  const {
    leftPaneCollapsed, collapseBottomPane, expandBottomPane, bottomPaneCollapsed,
    selectedTool,
  } = useLayoutContext();

  const {lastClickLocation, setLastClickLocation} = useMapContext();

  const [leftPaneSize, setLeftPaneSize] = useState(PANEL_LEFT_MAX_SIZE);
  const [bottomPaneSize, setBottomPaneSize] = useState(PANEL_BOTTOM_MAX_SIZE);
  const {
    selectedDestination,
    selectedRouteSegment,
    setSelectedDestinationId,
    setSelectedRouteSegmentId
  } = useRouteContext();

  const animate = (
    start: number,
    end: number,
    step: number,
    done: (from: number, to: number) => boolean,
    update: (size: number) => Promise<void>
  ) => {
    const stepFn = () => {
      if (!done(start, end)) {
        update(start += step).then(() => {
          window.requestAnimationFrame(stepFn)
        })
      }
    }

    stepFn()
  }

  const minimizeLeftPanel = () => {
    const update = (size: number): Promise<void> => {
      return new Promise((resolve) => {
        setLeftPaneSize(size < PANEL_COLLAPSED_SIZE ? PANEL_COLLAPSED_SIZE : size);
        resolve();
      })
    }

    const done = (from: number, to: number) => {
      return from < to
    }

    animate(
      leftPaneSize,
      0,
      -PANEL_ANIMATION_STEP,
      done,
      update
    )
  }

  const maximizeLeftPanel = () => {
    const update = (size: number): Promise<void> => {
      return new Promise((resolve) => {
        setLeftPaneSize(size > PANEL_LEFT_MAX_SIZE ? PANEL_LEFT_MAX_SIZE : size);
        resolve();
      })
    }

    const done = (from: number, to: number) => {
      return from > to
    }

    animate(
      leftPaneSize,
      PANEL_LEFT_MAX_SIZE,
      PANEL_ANIMATION_STEP,
      done,
      update
    )
  }

  const minimizeBottomPanel = () => {
    const update = (size: number): Promise<void> => {
      return new Promise((resolve) => {
        setBottomPaneSize(size < PANEL_COLLAPSED_SIZE ? PANEL_COLLAPSED_SIZE : size);
        resolve();
      })
    }

    const done = (from: number, to: number) => {
      return from < to
    }

    animate(
      bottomPaneSize,
      0,
      -PANEL_ANIMATION_STEP,
      done,
      update
    )
  }

  const maximizeBottomPanel = () => {
    const update = (size: number): Promise<void> => {
      return new Promise((resolve) => {
        setBottomPaneSize(size > PANEL_BOTTOM_MAX_SIZE ? PANEL_BOTTOM_MAX_SIZE : size);
        resolve();
      })
    }

    const done = (from: number, to: number) => {
      return from > to
    }

    animate(
      bottomPaneSize,
      PANEL_BOTTOM_MAX_SIZE,
      PANEL_ANIMATION_STEP,
      done,
      update
    )
  }

  useEffect(() => {
    if (leftPaneCollapsed && leftPaneSize < PANEL_LEFT_MAX_SIZE) {
      maximizeLeftPanel();
      return;
    }

    if (!leftPaneCollapsed && leftPaneSize === PANEL_LEFT_MAX_SIZE) {
      minimizeLeftPanel();
    }
  }, [leftPaneCollapsed]);


  useEffect(() => {
    if (bottomPaneCollapsed && bottomPaneSize < PANEL_BOTTOM_MAX_SIZE) {
      maximizeBottomPanel();
      return;
    }

    if (!bottomPaneCollapsed && bottomPaneSize === PANEL_BOTTOM_MAX_SIZE) {
      minimizeBottomPanel();
    }
  }, [bottomPaneCollapsed]);

  useEffect(() => {
    if (!selectedDestination && !selectedRouteSegment && !lastClickLocation) {
      collapseBottomPane();
      return;
    }

    if (!bottomPaneCollapsed) {
      expandBottomPane();
    }
  }, [selectedDestination, selectedRouteSegment, lastClickLocation]);

  const onResize = (event: HandlerProps) => {
    // const newSize = event.component.props.size;
    // if (!newSize) return;
    //
    // if (leftPaneSize !== newSize) {
    //   // setLeftPaneSize(newSize);
    // }
    //
    // if (newSize === PANEL_COLLAPSED_SIZE) {
    //   setLeftPaneCollapsed(true);
    // } else {
    //   setLeftPaneCollapsed(false);
    // }
  };

  useHotkeys('esc', () => {
    setSelectedDestinationId(null);
    setSelectedRouteSegmentId(null);
    setLastClickLocation(null);
  }, [selectedDestination, selectedRouteSegment, lastClickLocation]);

  if (!size.width || !size.height) {
    return (
      <Skeleton active/>
    )
  }

  const contentHeight = size.height - 50;

  return (
    <Container style={{width: size.width, height: contentHeight}}>
      <ReflexContainer
        orientation="vertical"
        style={{width: size.width, height: contentHeight}}
        windowResizeAware={false}
      >
        <ReflexElement
          size={leftPaneSize}
          minSize={PANEL_COLLAPSED_SIZE}
          maxSize={PANEL_LEFT_MAX_SIZE}
          propagateDimensions={true}
          onResize={onResize}
        >
          <RouteViewController/>
        </ReflexElement>
        <ReflexSplitter style={SPLITTER_STYLE}/>
        <ReflexElement
          propagateDimensions={true}
        >
          <ReflexContainer
            orientation="horizontal"
            windowResizeAware={false}
          >
            <ReflexElement
              propagateDimensions={true}
            >
              <MapViewController/>
            </ReflexElement>
            <ReflexSplitter style={SPLITTER_STYLE}/>
            <ReflexElement
              propagateDimensions={true}
              size={bottomPaneSize}
              minSize={PANEL_COLLAPSED_SIZE}
              maxSize={PANEL_BOTTOM_MAX_SIZE}
              onResize={onResize}
              direction={-1}
            >
              <DetailsViewController/>
            </ReflexElement>
          </ReflexContainer>
        </ReflexElement>
      </ReflexContainer>
      <MapContextMenu/>
      <RoutesList open={selectedTool === 'routes'}/>
      <AddressBook open={selectedTool === 'addresses'}/>
    </Container>
  );
};

export default MapLayout;
