/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/explicit-function-return-type */
import React from 'react';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import 'leaflet.gridlayer.googlemutant/dist/Leaflet.GoogleMutant';
// @ts-ignore
import {GoogleMutantType} from "leaflet";

export interface GoogleLayerProps {
  type: string;
}

const GoogleLayer = (props: GoogleLayerProps) => {
  return (
    <ReactLeafletGoogleLayer
      useGoogMapsLoader={false}
      type={props.type as GoogleMutantType}
    />
  );
};

export default GoogleLayer;
