import React from 'react';
import {Layout, theme} from 'antd';
import Header from "./components/Header";

const {Content} = Layout;

const MainLayout = (props: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const {
    token: {colorBgContainer},
  } = theme.useToken();

  return (
    <Layout>
      <Header />
      <Layout>
        <Content
          style={{
            padding: 0,
            margin: 0,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
