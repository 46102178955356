import React from 'react';
import styled from "styled-components";

interface DestinationIndexViewProps {
  index: number;
  total: number;
}

const MarkerContainer = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 16px;
  text-align: center;
  color: white;
  font-size: 11px;
  font-weight: bold;
  padding: 0;
  margin: 0;
`;

const TopLineContainer = styled.div`
  position: absolute;
  top: -1px;
  left: 20px;
  height: calc(50% - 12px);
  border-left: 2px solid #00b0ff;
  z-index: 25;
`;

const BottomLineContainer = styled.div`
  position: absolute;
  bottom: -1px;
  left: 20px;
  height: calc(50% - 12px);
  border-left: 2px solid #00b0ff;
  z-index: 25;
`;

const getBackgroundForIndex = (index: number, total: number): string => {
  if (index === 0) {
    return "#ab1d30";
  }

  if (index === total - 1) {
    return "#06750f";
  }

  return "#2b83cb";
};

const DestinationIndexView = (props: DestinationIndexViewProps): JSX.Element => {
  const {index, total} = props;

  return (
    <>
      {
        (index > 0) && (
          <TopLineContainer>
            &nbsp;
          </TopLineContainer>
        )
      }
      <MarkerContainer style={{background: getBackgroundForIndex(index, total)}}>
        <div style={{paddingTop: 3}}>
          {props.index + 1}
        </div>
      </MarkerContainer>
      {
        (index < total-1) && (
          <BottomLineContainer>
            &nbsp;
          </BottomLineContainer>
        )
      }
    </>
  );
};

export default DestinationIndexView;
