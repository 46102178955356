import { Avatar, Col, Row, Typography } from 'antd';
import React from 'react';
import { useUserContext } from "../../../context/UserContextProvider";
import {UserResponse} from "../../../api/client";

const { Text } = Typography;

const getUserAvatar = (user: UserResponse | null): JSX.Element | null => {
  if (!user) return null;
  const pictureUrl = user.pictureUrl;
  if (!pictureUrl) return null;

  return (
    <Avatar size="large" src={pictureUrl} style={{ width: 55, height: 55 }}/>
  );
};

const UserProfile = (): JSX.Element => {
  const { user } = useUserContext();

  return (
    <Row style={{ marginTop: '7px', marginBottom: '7px' }}>
      <Col span={6}>
        <Row>
          {getUserAvatar(user)}
        </Row>
      </Col>
      <Col span={18}>
        <Row>
          <Text>{user?.name || ''}</Text>
          <Text type="secondary">{user?.email || ''}</Text>
        </Row>
      </Col>
    </Row>
  );
};

export default UserProfile;
