import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useMapContext} from "../../../../context/MapContextProvider";

const STREET_VIEW_SIZE = 300;

const Container = styled.div`
  position: absolute;
  width: ${STREET_VIEW_SIZE}px;
  height: ${STREET_VIEW_SIZE}px;
  bottom: 30px;
  right: 5px;
  z-index: 940;
  border-radius: 10px;
  background: white;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  padding: 2px;
`;

export interface MapStreetViewControllerProps {
}

const MapStreetViewController = (props: MapStreetViewControllerProps): JSX.Element | null => {
  const [streetViewPanorama, setStreetViewPanorama] = useState<google.maps.StreetViewPanorama | undefined>(undefined);
  const { streetViewSettings } = useMapContext();
  const canvasRef = useRef(null);

  const properties: google.maps.StreetViewPanoramaOptions = {
    position: { lat: 46.9171876, lng: 17.8951832 },
    pov: { heading: 0, pitch: 0 },
    zoom: 1,
    addressControl: false,
    clickToGo: true,
    disableDoubleClickZoom: false,
    enableCloseButton: false,
    zoomControl: false,
    fullscreenControl: false,
    linksControl: false,
  };

  useEffect(() => {
    if (canvasRef && canvasRef.current) {
      const panorama = new google.maps.StreetViewPanorama(canvasRef.current, properties);
      setStreetViewPanorama(panorama);
    }
  }, [canvasRef]);

  useEffect(() => {
    if (streetViewSettings && streetViewPanorama) {
      streetViewPanorama.setPosition({
        lat: streetViewSettings.location.lat,
        lng: streetViewSettings.location.lng,
      })
    }
  }, [streetViewSettings, streetViewPanorama]);

  return (
    <Container>
      <div style={{
        width: '100%',
        height: '100%',
        borderRadius: 10
      }} ref={canvasRef} ></div>
    </Container>
  );
};

export default MapStreetViewController;
