import React, {useState} from "react";
import styled from "styled-components";
import {UserResponse} from "../../api/client";
import bgImg from "../../assets/images/bg.jpg";
import logoImg from "../../assets/images/logo/logo.png";
import {Card, Col, Row} from "antd";
import LoginWithEmail from "./components/LoginWithEmail";
import {UserCredential} from "@firebase/auth";
import LoginWithFacebook from "./components/LoginWithFacebook";
import LoginWithGoogle from "./components/LoginWithGoogle";
import RegisterWithEmail from "./components/RegisterWithEmail";
import {authAPI} from "../../api";
import loginBgImg from "../../assets/images/login/bg.jpg";

const Container = styled.div`
  background: url(${bgImg}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
`;

const BottomImage = styled.div`
  margin-left: -25px;
  margin-right: -25px;
  margin-bottom: -32px;

  img {
    width: 698px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const Title = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 20px;
  color: #888;
  font-family: Mochiy Pop One, serif;
`;

const Header = styled.div`
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
`;

interface LoginPageProps {
  onUserLoggedIn: (accessToken: string, user: UserResponse) => void;
}

const LoginPage = (props: LoginPageProps): JSX.Element => {
  const [mode, setMode] = useState<string>("login");

  const toggleMode = () => {
    setMode(mode === 'register' ? 'login' : 'register');
  };

  const userLoggedIn = (accessToken: string, user: UserResponse) => {
    props.onUserLoggedIn(accessToken, user);
  };

  const onUserLoggedIn = (creds: UserCredential) => {
    creds.user.getIdToken().then((idToken: string) => {
      authAPI.loginWithFirebase({idToken}).then((response) => {
        const token = response.data.token;
        const user = response.data.user;
        if (token && user) {
          userLoggedIn(token, user);
        }
      });
    });
  };

  return (
    <Container>
      <Card style={{width: 700, margin: 'auto', marginTop: "calc(8%)", boxShadow: "0 6px 15px rgba(0, 0, 0, 0.4)"}}>
        <Header>
          <Title>
            <img alt="Logo" src={logoImg} style={{width: 30, paddingRight: 10, verticalAlign: "bottom"}}/>
            Welcome to Route & Ride
          </Title>
        </Header>
        <div>
          <Row>
            <Col span={14} style={{paddingRight: 20, borderRight: "1px solid #eee"}}>

              {
                mode === 'register' && (
                  <>
                    <RegisterWithEmail onUserRegisteredWithEmail={onUserLoggedIn}/>
                    <div style={{marginTop: 20, color: "#888"}}>
                      Already have an account? <a href={'#'} onClick={toggleMode}>Login here.</a>
                    </div>
                  </>
                )
              }

              {
                mode === 'login' && (
                  <>
                    <LoginWithEmail onUserLoggedInWithEmail={onUserLoggedIn}/>
                    <div style={{marginTop: 20, color: "#888"}}>
                      Don't have an account? <a href={'#'} onClick={toggleMode}>Sign up here.</a>
                    </div>
                  </>
                )
              }

            </Col>
            <Col span={10} style={{paddingLeft: 20, textAlign: "center"}}>
              <div style={{paddingBottom: 20, color: "#888"}}>
                Or use other login methods
              </div>
              <div style={{textAlign: "center"}}>
                <LoginWithFacebook onUserLoggedInWithFacebook={onUserLoggedIn}/>
                <LoginWithGoogle onUserLoggedInWithGoogle={onUserLoggedIn}/>
              </div>
            </Col>
          </Row>
          <div style={{paddingBottom: 5}}>

          </div>
        </div>
        <BottomImage>
          <img src={loginBgImg} alt={"bg"}/>
        </BottomImage>
      </Card>
    </Container>
  );
};

export default LoginPage;
