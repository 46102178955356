import React, {useState} from "react";
import styled from "styled-components";
import {Alert, Button, Input} from "antd";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from '../../../firebase';
import {LockOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";
import {UserCredential} from "@firebase/auth";

const Container = styled.div`
`;

interface LoginWithEmailProps {
  onUserLoggedInWithEmail: (creds: UserCredential) => void;
}

const LoginWithEmail = (props: LoginWithEmailProps): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const logInWithEmailAndPassword = async () => {
    try {
      setError(null);
      const resp = await signInWithEmailAndPassword(auth, email, password);
      props.onUserLoggedInWithEmail(resp);
    } catch (err: any) {
      if (err.code === 'auth/invalid-email') {
        setError("Please enter a valid email");
      } else if (err.code === 'auth/wrong-password') {
        setError("The password is incorrect");
      } else if (err.code === 'auth/user-not-found') {
        setError("Incorrect email or password");
      } else {
        console.error(err);
      }
    }
  };

  return (
    <Container>
      <div style={{paddingBottom: 20, color: "#888"}}>
        Enter your email and password to login
      </div>

      {
        error && (
          <Alert message={error} type="error" style={{marginBottom: 10}}/>
        )
      }

      <div style={{marginBottom: 10}}>
        <Input
          size="large"
          name="email"
          autoFocus
          placeholder="name@domain.com"
          prefix={<MailOutlined />}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div style={{marginBottom: 20}}>
        <Input.Password
          name="password"
          size="large"
          placeholder="*********"
          prefix={<LockOutlined/>}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <Button block type={"primary"} onClick={logInWithEmailAndPassword}>Login</Button>
    </Container>
  );
};

export default LoginWithEmail;
