/* tslint:disable */
/* eslint-disable */
/**
 * Route & Ride API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface RouteCreateRequest
 */
export interface RouteCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof RouteCreateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RouteCreateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof RouteCreateRequest
     */
    'travelMode'?: RouteCreateRequestTravelModeEnum;
    /**
     * 
     * @type {string}
     * @memberof RouteCreateRequest
     */
    'departAt'?: string;
}

export const RouteCreateRequestTravelModeEnum = {
    Driving: 'DRIVING',
    Walking: 'WALKING',
    Bicycling: 'BICYCLING'
} as const;

export type RouteCreateRequestTravelModeEnum = typeof RouteCreateRequestTravelModeEnum[keyof typeof RouteCreateRequestTravelModeEnum];


