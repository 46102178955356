import React from 'react';
import {RouteDestination} from "../../../../types/RouteDestination";
import {ReactPhotoCollage} from "react-photo-collage";
import {FileImageOutlined} from "@ant-design/icons";

interface DestinationPhotosViewProps {
  destination: RouteDestination
}

const DestinationPhotosView = (props: DestinationPhotosViewProps): JSX.Element | null => {
  const {destination} = props;

  const photos = destination.photos?.map(it => {
    return {
      source: it.url
    }
  }) || [];

  const setting = {
    width: "400px",
    height: ["170px", "100px"],
    layout: [1, 4],
    showNumOfRemainingPhotos: true
  };

  return (
    <div>
      {
        photos.length > 0 && (
          <ReactPhotoCollage key={destination.id} {...setting} photos={photos}/>
        )
      }
      {
        photos.length === 0 && (
          <div style={{
            width: 400,
            border: "1px dashed #ccc",
            borderRadius: 5,
            height: 270,
            background: "#efefef",
            textAlign: "center",
            color: "#666"
          }}>
            <div style={{paddingTop: 100}}>
              <FileImageOutlined style={{fontSize: 42, marginBottom: 20}}/><br/>
              No photos for this destination.
            </div>
          </div>
        )
      }
    </div>
  );
};

export default DestinationPhotosView;
