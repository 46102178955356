import React from 'react';
import {Pane} from "react-leaflet";
import {useRouteContext} from "../../../../context/RouteContextProvider";
import MapRouteSegmentView from "./views/MapRouteSegmentView";

const MapRouteSegmentViewController = (): JSX.Element | null => {
  const {routeSegments, selectedRouteSegmentId} = useRouteContext();

  return (
    <Pane name="routes_pane" style={{zIndex: 400}}>
      {
        routeSegments.map((routeSegment, index) => {
          return (
            <MapRouteSegmentView
              key={routeSegment.id}
              routeSegment={routeSegment}
              index={index}
              total={routeSegments.length}
              selected={selectedRouteSegmentId === routeSegment.id}
            />
          );
        })
      }
    </Pane>
  );
};

export default MapRouteSegmentViewController;
