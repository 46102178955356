import React from 'react';
import styled from "styled-components";
import {Button, Card, Col, Descriptions, Row, Tag} from "antd";
import {useRouteContext} from "../../../../context/RouteContextProvider";
import RouteSegmentDetailsTableView from "./RouteSegmentDetailsTableView";
import RouteSegmentDirectionsTableView from "./RouteSegmentDirectionsTableView";
import {useMapContext} from "../../../../context/MapContextProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle, faMicrosoft, faWaze} from "@fortawesome/free-brands-svg-icons";
import {getBingUrl, getGoogleUrl, getWazeUrl, launchNewTab} from "../../../../helpers/url";

const Container = styled.div`
`;

const Header = styled.div`
  height: 40px;
  background: #f0f0f0;
  padding: 0 10px;
  border-bottom: 1px solid #ddd;
`;

const Body = styled.div`
  padding: 10px;
`;

const RouteSegmentDetailsViewController = (): JSX.Element | null => {
  const {selectedRouteSegment, getDestinationById} = useRouteContext();
  const {editRouteSegmentEnabled, setEditRouteSegmentEnabled} = useMapContext();

  const originId = selectedRouteSegment?.originId;
  const destinationId = selectedRouteSegment?.destinationId;

  if (!originId || !destinationId) {
    return null;
  }

  const origin = getDestinationById(originId);
  const destination = getDestinationById(destinationId);

  const toggleEditMode = () => {
    // setEditRouteSegmentEnabled(!editRouteSegmentEnabled);
  }

  const openExternalUrl = (vendor: string) => {
    if (!origin || !destination) {
      return;
    }

    let url: string | null = null;

    switch (vendor) {
      case "waze": {
        url = getWazeUrl([origin, destination]);
        break;
      }
      case "bing": {
        url = getBingUrl([origin, destination]);
        break;
      }
      case "google": {
        url = getGoogleUrl([origin, destination]);
        break;
      }
      default: {
        break;
      }
    }

    if (!url) {
      return;
    }

    launchNewTab(url);
  }

  return (
    <Container>
      <Header>
        <Row>
          <Col flex={1} style={{paddingTop: 10}}>
            <Tag color={"red"}>Drive</Tag>from <Tag color={"green"}>{origin?.address}</Tag>to <Tag
            color={"blue"}>{destination?.address}</Tag>
          </Col>
          <Col flex={"none"} style={{textAlign: "right", paddingTop: 6}}>
            <Button size={"small"} onClick={() => openExternalUrl('waze')} style={{marginRight: 3}}>
              <FontAwesomeIcon icon={faWaze} style={{color: '#666'}}/>
            </Button>
            <Button size={"small"} onClick={() => openExternalUrl('bing')} style={{marginRight: 3}}>
              <FontAwesomeIcon icon={faMicrosoft} style={{color: '#666'}}/>
            </Button>
            <Button size={"small"} onClick={() => openExternalUrl('google')} style={{marginRight: 3}}>
              <FontAwesomeIcon icon={faGoogle} style={{color: '#666'}}/>
            </Button>
          </Col>
        </Row>
      </Header>
      <Body>
        <Row>
          <Col span={12} style={{paddingRight: 10}}>
            <Card size={"small"} style={{marginBottom: 10, height: 270, overflow: "auto"}}>
              <RouteSegmentDirectionsTableView routeSegment={selectedRouteSegment}/>
            </Card>
          </Col>

          <Col span={8} style={{paddingRight: 10}}>
            <Descriptions bordered column={1} size={"small"} labelStyle={{width: 120}} contentStyle={{fontSize: 12}}
                          style={{marginBottom: 10}}>
              <Descriptions.Item label="Mode">{selectedRouteSegment.travelMode}</Descriptions.Item>
              <Descriptions.Item label="Vehicle">tbd</Descriptions.Item>
            </Descriptions>

            <Descriptions bordered column={1} size={"small"} labelStyle={{width: 120}} contentStyle={{fontSize: 12}}
                          style={{marginBottom: 10}}>
              <Descriptions.Item label="Depart At">n/a</Descriptions.Item>
              <Descriptions.Item label="Arrive At">n/a</Descriptions.Item>
            </Descriptions>

            <Card size={"small"} style={{marginTop: 45}}>
              <Button block onClick={toggleEditMode}>
                Update Route Segment
              </Button>
            </Card>

          </Col>
          <Col span={4}>
            <Card size={"small"} style={{marginBottom: 10, height: 270}}>
              <RouteSegmentDetailsTableView routeSegment={selectedRouteSegment}/>
            </Card>
          </Col>
        </Row>
      </Body>
    </Container>
  );
};

export default RouteSegmentDetailsViewController;
