import React from 'react';
import {Dimensions} from "../../../types/Dimensions";
import styled from "styled-components";
import {CaretDownOutlined, CaretLeftOutlined, CaretRightOutlined, CaretUpOutlined} from '@ant-design/icons';

const TabContainerLeft = styled.div`
  position: absolute;
  z-index: 900;
  background: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 20px;
  padding-left: 2px;
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  font-size: 14px;
  left: 0;
  height: 56px;
  width: 20px;
`;

const TabContainerBottom = styled.div`
  position: absolute;
  z-index: 900;
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  cursor: pointer;
  font-size: 14px;
  bottom: 0;
  height: 20px;
  width: 60px;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 0;
`;

interface TabViewProps {
  dimensions: Dimensions;
  anchor: string;
  collapsed: boolean;
  onClick: () => void;
}

const MapTabView = (props: TabViewProps): JSX.Element | null => {
  const {dimensions, anchor, collapsed} = props;

  if (anchor === "left") {
    return (
      <TabContainerLeft onClick={props.onClick} style={{
        top: parseInt(dimensions.height) / 2 - 18,
      }}>
        {
          collapsed ? (
            <CaretLeftOutlined/>
          ) : (
            <CaretRightOutlined/>
          )
        }
      </TabContainerLeft>
    );
  }

  if (anchor === "bottom") {
    return (
      <TabContainerBottom onClick={props.onClick} style={{
        left: parseInt(dimensions.width) / 2 - 30,
      }}>
        {
          collapsed ? (
            <CaretDownOutlined/>
          ) : (
            <CaretUpOutlined/>
          )
        }
      </TabContainerBottom>
    );
  }

  return null;
};

export default MapTabView;
