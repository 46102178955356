import React from 'react';
import {Button, Tooltip} from "antd";
import styled from "styled-components";
import {AddressResponse} from "../../../api/client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faGlobe, faLocationDot, faPencil, faPhone, faTrash} from "@fortawesome/free-solid-svg-icons";

interface AddressDetailsProps {
  address: AddressResponse;
  onEditAddress: () => void;
  onDeleteAddress: () => void;
  onAddToRoute: () => void;
}

const AddressView = styled.div`
  padding-left: 5px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  white-space: normal;
`;

const Address = styled.div`
  font-size: 11px;
  margin-top: 5px;
  white-space: normal;
`;

const Location = styled.div`
  font-size: 10px;
  margin-top: 5px;
  color: #888;
  max-width: 300px;
`;

const ButtonIcon = styled.div`
  display: inline-block;
  color: #a6a6a6;
  cursor: pointer;
  font-size: 12px;

  :hover {
    color: #666;
  }
`;

const AddressDetails = (props: AddressDetailsProps): JSX.Element => {
  const {address} = props;

  return (
    <AddressView>
      <div style={{float: 'right'}}>
        <Tooltip placement="topRight" title={'Edit address'}>
          <ButtonIcon onClick={props.onEditAddress} style={{marginRight: 8}}>
            <FontAwesomeIcon icon={faPencil}/>
          </ButtonIcon>
        </Tooltip>
        <Tooltip placement="topRight" title={'Delete address'}>
          <ButtonIcon onClick={props.onDeleteAddress}>
            <FontAwesomeIcon icon={faTrash}/>
          </ButtonIcon>
        </Tooltip>
      </div>
      <Title>
        {address.name}
      </Title>
      <Address>
        {address.address}
      </Address>
      {
        address.notes && (
          <Location>
            <FontAwesomeIcon icon={faCircleInfo} style={{paddingRight: 5}}/> {address.notes}
          </Location>
        )
      }
      {
        address.location && (
          <Location>
            <FontAwesomeIcon icon={faLocationDot}
                             style={{paddingRight: 5}}/> {address.location.lat}, {address.location.lng}
          </Location>
        )
      }
      {
        address.phone && (
          <Location>
            <FontAwesomeIcon icon={faPhone} style={{paddingRight: 5}}/> {address.phone}
          </Location>
        )
      }
      {
        address.url && (
          <Location>
            <FontAwesomeIcon icon={faGlobe} style={{paddingRight: 5}}/> <a href={address.url}
                                                                           target={'_new'}>{address.url}</a>
          </Location>
        )
      }
      <div style={{marginTop: 10}}>
        <Button
          onClick={props.onAddToRoute}
          size={'small'}>Add To Route</Button>
      </div>
    </AddressView>
  );
};

export default AddressDetails;
