import React from 'react';
import {Col, Row} from "antd";
import {RouteDestination} from "../../../../../types/RouteDestination";
import {formatMinutesAsDaysHoursMinutes} from "../../../../../helpers/time";
import {faCalendar, faClock} from "@fortawesome/free-regular-svg-icons";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import {Dayjs} from "dayjs";

interface DestinationViewProps {
  destination: RouteDestination;

  onClick: () => void;

  onDblClick: () => void;

  arriveAt?: Dayjs | null;

  departAt?: Dayjs | null;
}

const TimeLabel = styled.div`
  display: inline-block;
  margin-right: 2px;
`;

const DestinationView = (props: DestinationViewProps): JSX.Element => {
  const {destination, arriveAt, departAt} = props;

  const interval = destination.stayTimeInMin ? formatMinutesAsDaysHoursMinutes(destination.stayTimeInMin) : null;

  return (
    <div style={{cursor: 'pointer'}} onClick={props.onClick} onDoubleClick={props.onDblClick}>
      <Row>
        <Col span={24}>
          {destination.name}
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{fontSize: 10, color: "#666"}}>
          {destination.address}
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{fontSize: 10, color: "#666"}}>
          {
            interval && (
              <div style={{display: "inline-block", marginRight: 10, marginTop: 5}}>
                <FontAwesomeIcon icon={faClock}/>
                <TimeLabel style={{marginLeft: 5}}>+</TimeLabel>
                {interval}
              </div>
            )
          }
          {
            (interval && arriveAt && departAt) && (
              <>
                <FontAwesomeIcon icon={faCalendar} style={{marginRight: 5}}/>
                <TimeLabel>{arriveAt.format('hh:mm a')}</TimeLabel>
                <FontAwesomeIcon icon={faArrowRight} style={{marginLeft: 5, marginRight: 5}}/>
                <TimeLabel>{departAt.format('hh:mm a')}</TimeLabel>
              </>
            )
          }
        </Col>
      </Row>
    </div>
  );
};

export default DestinationView;
