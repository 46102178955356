import React, {useState} from "react";
import styled from "styled-components";
import {Alert, Button, Input} from "antd";
import {createUserWithEmailAndPassword} from "firebase/auth";
import {auth} from '../../../firebase';
import {LockOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";
import {UserCredential} from "@firebase/auth";

const Container = styled.div`
`;

interface RegisterWithEmailProps {
  onUserRegisteredWithEmail: (creds: UserCredential) => void;
}

const RegisterWithEmail = (props: RegisterWithEmailProps): JSX.Element => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const registerWithEmailAndPassword = async () => {
    try {
      const response = await createUserWithEmailAndPassword(auth, email, password);
      props.onUserRegisteredWithEmail(response);
    } catch (err: any) {
      if (err.code === 'auth/email-already-in-use') {
        setError("A user with this email already exists");
      } else {
        console.error(err);
      }
    }
  };

  return (
    <Container>
      <div style={{paddingBottom: 20, color: "#888"}}>
        Enter your name, email and password to sign up
      </div>

      {
        error && (
          <Alert message={error} type="error" style={{marginBottom: 10}}/>
        )
      }

      <div style={{marginBottom: 10}}>
        <Input
          size="large"
          autoFocus
          placeholder="John Smith"
          prefix={<UserOutlined/>}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div style={{marginBottom: 10}}>
        <Input
          size="large"
          placeholder="name@domain.com"
          prefix={<MailOutlined/>}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div style={{marginBottom: 10}}>
        <Input.Password
          size="large"
          placeholder="***********"
          prefix={<LockOutlined/>}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <Button block type={"primary"} onClick={registerWithEmailAndPassword}>Sign Up</Button>
    </Container>
  );
};

export default RegisterWithEmail;
