import React, {useContext, useEffect, useMemo, useState} from "react";
import UserContext, {UserContextType} from "./UserContext";
import {UserResponse} from "../api/client";
import LoginPage from "../pages/login/LoginPage";
import {signOut} from "firebase/auth";
import {auth} from "../firebase";
import {authAPI, buildAuthHeaders} from "../api";
import {Navigate, useLocation, useNavigate} from "react-router-dom";

const LOCAL_STORAGE_TOKEN_PARAM = "token";

export interface UserContextProviderOptions {
  debug?: boolean;
}

export interface UserContextProviderProps {
  opts?: UserContextProviderOptions;
  children: JSX.Element;
}

export const UserContextProvider: React.FC<UserContextProviderProps> = (props) => {
  const [user, setUser] = useState<UserResponse | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_PARAM)
    if (token) {
      authAPI.getCurrentUser({
        headers: buildAuthHeaders(token)
      }).then(response => {
        setAccessToken(token);
        setUser(response.data);
      }).catch(() => {
        setAccessToken(null);
        setUser(null);
      })
    }
  }, []);

  const onUserLoggedIn = (accessToken: string, user: UserResponse) => {
    localStorage.setItem(LOCAL_STORAGE_TOKEN_PARAM, accessToken)
    setAccessToken(accessToken);
    setUser(user);
  };

  const onUserLoggedOut = () => {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_PARAM)

    signOut(auth);

    setUser(null);
    setAccessToken(null);
  };

  const value = useMemo(() => {
    return {
      user: user,
      accessToken: accessToken,
      logout: onUserLoggedOut
    }
  }, [user, accessToken]);

  if (!user) {
    // if (location.pathname !== '/') {
    //   return (
    //     <Navigate to={"/"} />
    //   );
    // }

    return (
      <LoginPage
        onUserLoggedIn={onUserLoggedIn}
      />
    )
  }

  return (
    <UserContext.Provider value={value}>
      {props.children}
    </UserContext.Provider>
  );
};

export const useUserContext: () => UserContextType = () => {
  return useContext(UserContext);
};
