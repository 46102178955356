import {ContactsOutlined, DeleteOutlined, PictureOutlined, PushpinOutlined,
  SaveOutlined, ZoomInOutlined, ZoomOutOutlined,} from '@ant-design/icons';
import styled from 'styled-components';
import React, {CSSProperties} from "react";
import {useMapContext} from "../../../../context/MapContextProvider";
import ShortcutKeys from "../../../../components/ShortcutKeys";
import {v4 as uuidv4} from 'uuid';
import {useRouteContext} from "../../../../context/RouteContextProvider";
import {useNavigate} from "react-router-dom";
import {useLayoutContext} from "../../../../context/LayoutContextProvider";

const Popup = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  z-index: 950;
  width: 250px;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  text-align: left;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

const Menu = styled.ul`
  list-style-type: none;
  outline: none;
  margin: 0;
  padding: 0;
`;

const MenuItem = styled.li`
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 11px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 5px 12px;
  transition: all .3s;
  white-space: nowrap;

  &:hover {
    background-color: #e6f7ff;
  }
`;

const Separator = styled.li`
  border-top: 1px solid #eee;
  height: 1px;
  padding: 0;
  margin: 0;
`;

const shortcutDefaultStyle: CSSProperties = {
  fontSize: 8,
  float: 'right',
};

const MapContextMenu = (): JSX.Element | null => {
  const {contextMenu, hideContextMenu, zoomIn, zoomOut, toggleStreetViewSettings} = useMapContext();
  const {addDestination, selectedDestination, removeDestination} = useRouteContext();
  const navigate = useNavigate();
  const {
    setSelectedTool
  } = useLayoutContext();

  if (!contextMenu) {
    return null;
  }

  const onZoomIn = () => {
    zoomIn();
    hideContextMenu();
  };

  const onZoomOut = () => {
    zoomOut();
    hideContextMenu();
  };

  const addNewPlace = () => {
    addDestination({
      id: uuidv4(),
      name: 'Dropped pin',
      location: contextMenu.location,
    });
    hideContextMenu();
  };

  const toggleStreetView = () => {
    toggleStreetViewSettings(selectedDestination?.location);
    hideContextMenu();
  };

  const viewSavedRoutes = () => {
    setSelectedTool("routes");
    hideContextMenu();
  };

  const viewAddressBook = () => {
    setSelectedTool("addresses");
    hideContextMenu();
  };

  const removeSelectedDestination = () => {
    if (contextMenu.destination) {
      removeDestination(contextMenu.destination.id);
    }
    hideContextMenu();
  }

  return (
    <Popup style={{left: contextMenu.position.left, top: contextMenu.position.top}}>
      <Menu>
        {
          contextMenu.destination && (
            <>
              <MenuItem
                onClick={removeSelectedDestination}
              >
                <DeleteOutlined style={{marginRight: 5}}/> Remove from route
              </MenuItem>
              <Separator/>
            </>
          )
        }

        {
          (!contextMenu.destination && contextMenu.location) && (
            <>
              <MenuItem
                onClick={addNewPlace}
              >
                <PushpinOutlined style={{marginRight: 5}}/> Add to route
              </MenuItem>
              <Separator/>
            </>
          )
        }

        <MenuItem
          onClick={onZoomIn}
        >
          <ZoomInOutlined style={{marginRight: 5}}/> Zoom in
          <ShortcutKeys keys={'Z'} style={shortcutDefaultStyle}/>
        </MenuItem>
        <MenuItem
          onClick={onZoomOut}
        >
          <ZoomOutOutlined style={{marginRight: 5}}/> Zoom out
          <ShortcutKeys keys={'shift+Z'} style={shortcutDefaultStyle}/>
        </MenuItem>
        <Separator/>
        <MenuItem
          onClick={toggleStreetView}
        >
          <PictureOutlined style={{marginRight: 7}}/>
          Toggle street view
          <ShortcutKeys keys={'S'} style={shortcutDefaultStyle}/>
        </MenuItem>
        <Separator/>
        <MenuItem
          onClick={viewSavedRoutes}
        >
          <SaveOutlined style={{marginRight: 7}}/>
          Saved Routes
          <ShortcutKeys keys={'ctrl+shift+r'} style={shortcutDefaultStyle}/>
        </MenuItem>
        <MenuItem
          onClick={viewAddressBook}
        >
          <ContactsOutlined style={{marginRight: 7}}/>
          Address Book
          <ShortcutKeys keys={'ctrl+shift+a'} style={shortcutDefaultStyle}/>
        </MenuItem>
      </Menu>
    </Popup>
  );
};

export default MapContextMenu;
