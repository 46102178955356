import React from 'react';
import {Polyline} from "react-leaflet";
import {PathOptions} from "leaflet";
import {RouteSegment} from "../../../../../types/RouteSegment";

interface MapRouteSegmentViewProps {
  routeSegment: RouteSegment;
  index: number;
  total: number;
  selected: boolean;
}

const defaultPathOptions: PathOptions = {
  color: '#00b0ff',
  weight: 5,
  // stroke: true,
}

const MapRouteSegmentView = (props: MapRouteSegmentViewProps): JSX.Element | null => {
  const {routeSegment, selected} = props;
  const color = selected ? {color: "#0072a1"} : {};

  const pathOptions = {
    ...defaultPathOptions, ...color
  };

  return (
    <Polyline
      key={`route_path_${routeSegment.id}`}
      pathOptions={pathOptions}
      positions={routeSegment.path}
      className={`route_segment_${routeSegment.id}`}
    />
  );
};

export default MapRouteSegmentView;
