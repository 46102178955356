import React, {useContext, useEffect, useMemo, useState} from "react";
import RouteContext, {defaultRoute, RouteContextType} from "./RouteContext";
import {useGoogleContext} from "./GoogleContextProvider";
import {LatLng} from "leaflet";
import {v4 as uuidv4} from 'uuid';
import {useMapContext} from "./MapContextProvider";
import {Route} from "../types/Route";
import {RouteSegment} from "../types/RouteSegment";
import {RouteDestination} from "../types/RouteDestination";
import {useUserContext} from "./UserContextProvider";
import {
  DestinationApi,
  LocationResponse,
  PhotoResponse,
  RouteApi,
  RouteCreateRequestTravelModeEnum,
  RouteDestinationResponse,
  RouteResponse,
  RouteResponseTravelModeEnum,
  RouteSegmentInstructionResponse,
  RouteSegmentResponse,
  RouteUpdateRequestTravelModeEnum
} from "../api/client";
import {buildConfiguration} from "../api";
import {RouteSegmentInstruction} from "../types/RouteSegmentInstruction";
import styled from "styled-components";
import bgImg from "../assets/images/bg.jpg";
import {SyncOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {Photo} from "../types/Photo";
import {useLocation, useNavigate, useParams} from "react-router-dom";

const LoadingContainer = styled.div`
  background: url(${bgImg}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
`;

export interface RouteContextProviderOptions {
  debug?: boolean;
}

export interface RouteContextProviderProps {
  opts?: RouteContextProviderOptions;
  children: JSX.Element;
}

const locationResponseToLatLng = (response: LocationResponse): LatLng => {
  return new LatLng(response.lat as number, response.lng as number);
}

const photoResponseToPhoto = (response: PhotoResponse): Photo => {
  return {
    id: response.id!,
    url: response.url!
  };
}

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mmZ";

const instructionResponseToRouteSegmentInstruction = (
  response: RouteSegmentInstructionResponse
): RouteSegmentInstruction => {
  return {
    id: response.id!,
    details: response.details!,
    maneuver: response.maneuver || null,
    durationInSeconds: response.durationInSeconds || null,
    distanceInMeters: response.distanceInMeters || null,
    path: response.path?.map(it => locationResponseToLatLng(it)) || [],
  };
}

const destinationResponseToDestination = (
  response: RouteDestinationResponse
): RouteDestination => {
  const dest: RouteDestination = {
    id: response.id!,
    placeId: response.placeId || null,
    name: response.name!,
    notes: response.notes || null,
    location: locationResponseToLatLng(response.location!),
    address: response.address,
    phone: response.phone,
    iconUrl: response.iconUrl,
    photos: response.photos?.map(it => photoResponseToPhoto(it)),
    stayTimeInMin: response.stayTimeInMin,
    url: response.url,
    addressId: response.addressId,
  }

  return dest;
}

const segmentResponseToSegment = (
  response: RouteSegmentResponse
): RouteSegment => {
  const seg: RouteSegment = {
    id: response.id!,
    originId: response.originId!,
    destinationId: response.destinationId!,
    travelMode: response.travelMode!,
    durationInSeconds: response.durationInSeconds || 0,
    distanceInMeters: response.distanceInMeters || 0,
    path: response.path?.map(it => locationResponseToLatLng(it)) || [],
    instructions: response.instructions?.map(it => instructionResponseToRouteSegmentInstruction(it)) || [],
  }

  return seg;
}

const routeResponseToRoute = (
  response: RouteResponse
): Route => {
  const rt: Route = {
    id: response.id as string,
    name: response.name as string,
    description: response.description || null,
    departAt: response.departAt ? dayjs(response.departAt, DATE_TIME_FORMAT) : dayjs(),
    travelMode: (response.travelMode as RouteResponseTravelModeEnum).toString(),
    destinations: response.destinations?.map(it => destinationResponseToDestination(it)),
    segments: response.segments?.map(it => segmentResponseToSegment(it)),
    // overview?: RouteSegment;
  }

  return rt;
}

export const RouteContextProvider: React.FC<RouteContextProviderProps> = (props) => {
  const {accessToken} = useUserContext();
  const {routeId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState<string | null>(null);

  const [route, setRoute] = useState<Route>(defaultRoute);
  const [overviewSegment, setOverviewSegment] = useState<RouteSegment | null>(null);
  const [destinations, setDestinations] = useState<RouteDestination[]>([]);
  const [routeSegments, setRouteSegments] = useState<RouteSegment[]>([]);
  const [selectedDestinationId, setSelectedDestinationIdState] = useState<string | null>(null);
  const [selectedRouteSegmentId, setSelectedRouteSegmentIdState] = useState<string | null>(null);

  const {streetViewSettings, setStreetViewSettings, setLastClickLocation} = useMapContext();

  const {getAddressByLocation, getDirections} = useGoogleContext();

  const routeApi = useMemo(() => {
    return new RouteApi(buildConfiguration(accessToken));
  }, [accessToken]);

  const destinationApi = useMemo(() => {
    return new DestinationApi(buildConfiguration(accessToken));
  }, [accessToken]);

  const updateRouteState = (rt: Route) => {
    setRoute(rt);
    setDestinations(rt.destinations || []);
    setRouteSegments(rt.segments || []);
  };

  // initially routeId is null
  useEffect(() => {
    if (routeId) {
      return
    }

    routeApi.getLastOrNewRoute()
      .then(response => {
        navigate(`/routes/${response.data.id}`);
      })
      .catch(err => {
        setError(err.message);
      });
  }, [routeId]);

  // when routes id changes, load a new routes
  useEffect(() => {
    if (!routeId) {
      return
    }

    routeApi.getRouteById(routeId, true)
      .then(response => {
        updateRouteState(routeResponseToRoute(response.data));
      })
      .catch(err => {
        setError(err.message);
      });
  }, [routeId]);

  const createDestination = (newDestination: RouteDestination) => {
    destinationApi.createDestination({
      routeId: route.id,
      name: newDestination.name,
      location: newDestination.location,
      position: destinations.length,
      description: newDestination.description || undefined,
      googlePlaceId: newDestination.placeId || undefined,
      address: newDestination.address || undefined,
      phone: newDestination.phone || undefined,
      iconUrl: newDestination.iconUrl || undefined,
      url: newDestination.url || undefined,
    }).then(resp => {
      const dest = destinationResponseToDestination(resp.data);
      setDestinations([...destinations, dest]);
      setSelectedDestinationId(dest.id!);
    }).catch(ex => {
      setError(ex.message);
    });
  };

  const addDestination = (newDestination: RouteDestination) => {
    if (newDestination.placeId || newDestination.address) {
      createDestination(newDestination);
      return;
    }

    getAddressByLocation(newDestination.location).then((result) => {
      createDestination({
        ...newDestination,
        ...{
          placeId: result.place_id,
          address: result.formatted_address,
        }
      });
    }).catch((error) => {
      setDestinations([...destinations, newDestination]);
      setSelectedDestinationId(newDestination.id);
      setLastClickLocation(null);
    });
  };

  const removeDestination = (id: string) => {
    destinationApi.deleteDestination({
      id: id
    }).then(() => {
      setDestinations(destinations.filter(it => it.id !== id));
    }).catch((error) => {
      setError(error.message);
    });
  }

  const replaceDestination = (dest: RouteDestination) => {
    setDestinations(destinations.map(destination => {
      return (dest.id === destination.id) ? dest : destination;
    }))
    setSelectedDestinationId(dest.id);
  }

  const updateDestination = (destination: RouteDestination) => {
    destinationApi.updateDestination({
      id: destination.id,
      location: {lat: destination.location.lat, lng: destination.location.lng},
      address: destination.address || undefined,
      name: destination.name || undefined,
      description: destination.description || undefined,
      phone: destination.phone || undefined,
      iconUrl: destination.iconUrl || undefined,
      url: destination.url || undefined,
      stayTimeInMin: destination.stayTimeInMin || undefined,
    }).then((response) => {
      replaceDestination(destinationResponseToDestination(response.data));
    }).catch(ex => {
      setError(ex.message);
    });
  }

  const addDestinationToAddressBook = (id: string) => {
    destinationApi.addToAddressBook({
      id: id,
    }).then((response) => {
      replaceDestination(destinationResponseToDestination(response.data));
    }).catch(ex => {
      setError(ex.message);
    });
  }

  const removeDestinationFromAddressBook = (id: string) => {
    destinationApi.removeFromAddressBook({
      id: id,
    }).then((response) => {
      replaceDestination(destinationResponseToDestination(response.data));
    }).catch(ex => {
      setError(ex.message);
    });
  }

  const orderDestinations = (destinations: RouteDestination[]) => {
    destinationApi.orderDestinations({
      routeId: route.id,
      ids: destinations.map(it => it.id)
    }).then((response) => {
      setDestinations(destinations);
    }).catch(err => {
      setError(err.message);
    });
  }

  const moveDestination = (id: string, location: LatLng) => {
    const destination = getDestinationById(id);
    if (!destination) return;

    getAddressByLocation(location).then((result) => {
      updateDestination({
        ...destination,
        ...{
          location: new LatLng(location.lat, location.lng),
          address: result.formatted_address
        }
      });
    }).catch((error) => {
      updateDestination({
        ...destination,
        ...{
          location: new LatLng(location.lat, location.lng)
        }
      });
    });
  };

  const getDestinationById = (id: string): RouteDestination | null => {
    return destinations.find(it => it.id === id) || null;
  };

  const getRouteSegmentById = (id: string): RouteSegment | null => {
    return routeSegments.find(it => it.id === id) || null;
  };

  const selectedDestination = selectedDestinationId ? getDestinationById(selectedDestinationId) : null;
  const selectedRouteSegment = selectedRouteSegmentId ? getRouteSegmentById(selectedRouteSegmentId) : null;

  useEffect(() => {
    if (destinations.length < 2) {
      setOverviewSegment(null);
      return;
    }

    const waypoints = destinations.map(it => it.location);

    getDirections(waypoints, route.travelMode)
      .then(((directionsRoute: google.maps.DirectionsRoute) => {
        // console.log(directionsRoute);

        const points = directionsRoute.overview_path.map(point => {
          return new LatLng(point.lat(), point.lng());
        });

        let totalDuration = 0;
        let totalDistance = 0;

        const routeSegments: RouteSegment[] = [];

        directionsRoute.legs.forEach((directionsLeg: google.maps.DirectionsLeg, index: number) => {
          const instructions: RouteSegmentInstruction[] = [];

          if (directionsLeg.duration?.value) {
            totalDuration += directionsLeg.duration.value;
          }
          if (directionsLeg.distance?.value) {
            totalDistance += directionsLeg.distance?.value;
          }

          const points: LatLng[] = [];
          directionsLeg.steps.forEach((directionsStep: google.maps.DirectionsStep) => {
            const stepPath: LatLng[] = [];

            directionsStep.path.forEach(it => {
              const pt = new LatLng(it.lat(), it.lng());
              stepPath.push(pt);
              points.push(pt);
            })

            instructions.push({
              id: uuidv4(),
              maneuver: directionsStep.maneuver,
              details: directionsStep.instructions,
              durationInSeconds: directionsStep.duration?.value || null,
              distanceInMeters: directionsStep.distance?.value || null,
              path: stepPath,
            });
          });

          routeSegments.push({
            id: uuidv4(),
            originId: destinations[index].id,
            destinationId: destinations[index + 1].id,
            path: points,
            travelMode: route.travelMode,
            durationInSeconds: directionsLeg.duration?.value || 0,
            distanceInMeters: directionsLeg.distance?.value || 0,
            instructions: instructions
          });
        });

        setRouteSegments(routeSegments);

        destinations.forEach(dest => {
          totalDuration += (dest.stayTimeInMin || 0) * 60;
        });

        const segment: RouteSegment = {
          id: uuidv4(),
          originId: destinations[0].id,
          destinationId: destinations[destinations.length - 1].id,
          path: points,
          travelMode: route.travelMode,
          durationInSeconds: totalDuration,
          distanceInMeters: totalDistance
        };

        setOverviewSegment(segment);
      }))
      .catch(reason => {
        console.log(reason)
      });
  }, [destinations, route]);

  const setSelectedDestinationId = (id: string | null) => {
    if (id) {
      setLastClickLocation(null);
      setSelectedRouteSegmentIdState(null);
    }
    setSelectedDestinationIdState(id);
  };

  const setSelectedRouteSegmentId = (id: string | null) => {
    if (id) {
      setLastClickLocation(null);
      setSelectedDestinationIdState(null);
    }
    setSelectedRouteSegmentIdState(id);
  };

  useEffect(() => {
    if (selectedDestination && streetViewSettings) {
      setStreetViewSettings({...streetViewSettings, ...{location: selectedDestination.location}})
    }
  }, [selectedDestination]);

  const newRoute = () => {
    routeApi
      .createRoute({
        name: 'My Route',
        travelMode: RouteCreateRequestTravelModeEnum.Driving
      })
      .then((response) => {
        navigate(`/routes/${response.data.id}`)
      })
      .catch(err => {
        setError(err.message);
      })
  }

  const updateRoute = (route: Route) => {
    routeApi.updateRoute({
      id: route.id,
      name: route.name,
      description: route.description || undefined,
      departAt: route.departAt?.format(DATE_TIME_FORMAT) || undefined,
      travelMode: route.travelMode as RouteUpdateRequestTravelModeEnum
    }).then((response) => {
      setRoute(routeResponseToRoute(response.data));
    }).catch(err => {
      setError(err.message);
    });
  }

  const value = useMemo<RouteContextType>(() => {
    if (props.opts?.debug) {
      console.log('Updating routes context');
    }

    return {
      routeApi,
      route,
      updateRoute,
      destinations,
      addDestination,
      updateDestination,
      removeDestination,
      moveDestination,
      orderDestinations,
      setDestinations,
      getDestinationById,
      routeSegments,
      setRouteSegments,
      selectedDestinationId,
      setSelectedDestinationId,
      selectedDestination,
      getRouteSegmentById,
      selectedRouteSegmentId,
      setSelectedRouteSegmentId,
      selectedRouteSegment,
      overviewSegment,
      setOverviewSegment,
      addDestinationToAddressBook,
      removeDestinationFromAddressBook,
      newRoute
    };
  }, [
    route, destinations, overviewSegment, routeSegments,
    selectedDestinationId, selectedDestination,
    selectedRouteSegmentId, selectedRouteSegment
  ]);

  if (route.id === '') {
    return (
      <LoadingContainer>
        <div style={{textAlign: 'center', paddingTop: 'calc(30%)', fontSize: 40}}>
          <SyncOutlined spin style={{marginRight: 10}}/> Initializing...
        </div>
      </LoadingContainer>
    );
  }

  return (
    <RouteContext.Provider value={value}>
      {props.children}
    </RouteContext.Provider>
  );
};

export const useRouteContext: () => RouteContextType = () => {
  return useContext(RouteContext);
};
