import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Row} from "antd";
import {RouteDestination} from "../../../../types/RouteDestination";
import styled from "styled-components";
import {useRouteContext} from "../../../../context/RouteContextProvider";
import DurationSelector from "../../../../components/DurationSelector";
import {useMapContext} from "../../../../context/MapContextProvider";

interface DestinationFormViewProps {
  destination: RouteDestination,
  lastClick?: boolean,
}

const Label = styled.div`
  color: #888;
  margin-bottom: 5px;
`;

const DestinationFormView = (props: DestinationFormViewProps): JSX.Element | null => {
  const {destination, lastClick} = props;
  const [name, setName] = useState<string>(destination.name);
  const [description, setDescription] = useState<string>(destination.description || '');
  const [stayTimeInMin, setStayTimeInMin] = useState<number>(destination.stayTimeInMin || 0);
  const {updateDestination, removeDestination, addDestination} = useRouteContext();
  const {setLastClickLocation} = useMapContext();

  useEffect(() => {
    setName(destination.name)
    setDescription(destination.description || '');
    setStayTimeInMin(destination.stayTimeInMin || 0);
  }, [destination]);

  const removeDestinationFromList = () => {
    removeDestination(destination.id);
  };

  const clearLastClick = () => {
    setLastClickLocation(null);
  };

  const resetDetails = () => {
    setName(destination.name)
    setDescription(destination.description || '');
  };

  const addToRoute = () => {
    addDestination({
      ...destination,
      ...{
        name: name,
        description: description,
        stayTimeInMin: stayTimeInMin
      }
    })
  };

  const updateDestinationDetails = () => {
    updateDestination({
      ...destination,
      ...{
        name: name,
        description: description,
        stayTimeInMin: stayTimeInMin
      }
    })
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Label>Destination Name</Label>
          <Input
            value={name}
            autoFocus
            style={{marginBottom: 10}}
            onChange={event => setName(event.target.value)}
            onPressEnter={updateDestinationDetails}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Label>How long are you planning to stay here?</Label>
          <DurationSelector durationInMin={stayTimeInMin} onChange={setStayTimeInMin}/>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Label>Description</Label>
          <Input.TextArea
            value={description}
            style={{height: 55, marginBottom: 10}}
            onChange={event => setDescription(event.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          {
            !lastClick && (
              <Button size={"small"} danger onClick={removeDestinationFromList}>Remove From Route</Button>
            )
          }
          {
            lastClick && (
              <Button size={"small"} onClick={clearLastClick}>Clear</Button>
            )
          }
        </Col>
        <Col span={18} style={{textAlign: "right"}}>
          <Button size={"small"} onClick={resetDetails}>Reset</Button> &nbsp;
          {
            lastClick && (
              <Button size={"small"} type={"primary"} onClick={addToRoute}>Add To Route</Button>
            )
          }
          {
            !lastClick && (
              <Button size={"small"} type={"primary"} onClick={updateDestinationDetails}>Update Destination</Button>
            )
          }
        </Col>
      </Row>
    </>
  );
};

export default DestinationFormView;
