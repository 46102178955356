import {Select, SelectProps} from 'antd';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {CalendarOutlined} from "@ant-design/icons";
import {daysHoursMinutesToMinutes, minutesToDaysHoursMinutes} from "../helpers/time";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faCalendar} from "@fortawesome/free-regular-svg-icons";

interface DurationSelectorProps {
  durationInMin: number;
  onChange: (durationInMin: number) => void;
}

const Container = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
  color: #888;
`;

const Label = styled.div`
  display: inline-block;
  margin-left: 5px;
  margin-right: 20px;
`;

const ClearButton = styled.div`
  float: right;
  color: #ccc;
  
  :hover {
    color: #666;
  }
`;

const daysOptions: SelectProps['options'] = [];
for (let i = 0; i < 365; i++) {
  daysOptions.push({
    value: i,
    label: i.toString(),
  });
}

const hoursOptions: SelectProps['options'] = [];
for (let i = 0; i < 24; i++) {
  hoursOptions.push({
    value: i,
    label: i.toString(),
  });
}

const minutesOptions: SelectProps['options'] = [];
for (let i = 0; i < 60; i++) {
  minutesOptions.push({
    value: i,
    label: i.toString(),
  });
}

const DurationSelector = (props: DurationSelectorProps): JSX.Element => {
  const {durationInMin} = props;
  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);

  useEffect(() => {
    const dhm = minutesToDaysHoursMinutes(durationInMin);
    setDays(dhm.days);
    setHours(dhm.hours);
    setMinutes(dhm.minutes);
  }, [durationInMin]);

  useEffect(() => {
    const totalMin = daysHoursMinutesToMinutes({days, hours, minutes});
    props.onChange(totalMin);
  }, [days, hours, minutes]);

  const handleDaysChange = (value: number) => {
    setDays(value);
  }

  const handleHoursChange = (value: number) => {
    setHours(value);
  }

  const handleMinutesChange = (value: number) => {
    setMinutes(value);
  }

  const clearValue = () => {
    props.onChange(0);
  }

  return (
    <Container>
      <ClearButton>
        <FontAwesomeIcon
          icon={faCircleXmark}
          style={{ cursor: "pointer" }}
          onClick={clearValue}
        />
      </ClearButton>

      <FontAwesomeIcon
        icon={faCalendar}
        style={{ color: "#ccc", fontSize: 22, marginRight: 10, verticalAlign: "top" }}
      />

      <Select
        size={"small"}
        value={days}
        onChange={handleDaysChange}
        style={{width: 70}}
        options={daysOptions}
      />
      <Label>Days</Label>

      <Select
        size={"small"}
        value={hours}
        onChange={handleHoursChange}
        style={{width: 60}}
        options={hoursOptions}
      />
      <Label>Hours</Label>

      <Select
        size={"small"}
        value={minutes}
        onChange={handleMinutesChange}
        style={{width: 60}}
        options={minutesOptions}
      />
      <Label>Minutes</Label>
    </Container>
  );
};

export default DurationSelector;
