import React from "react";
import {MapContainer} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {Skeleton} from "antd";
import {Dimensions} from "../types/Dimensions";
import MapEventController from "./map/MapEventController";
import MapRouteDestinationViewController from "./map/MapRouteDestinationViewController";
import MapRouteSegmentViewController from "./map/MapRouteSegmentViewController";
import MapGeoManController from "./map/MapGeoManController";
import MapLayerViewController from "./map/MapLayerViewController";
import MapTabView from "./map/views/MapTabView";
import MapMenuBarViewController from "./map/MapMenuBarViewController";
import MapCurrentUserView from "./map/views/MapCurrentUserView";
import {defaultLocation, defaultZoomLevel} from "../../../context/MapContext";
import MapStreetViewController from "./map/MapStreetViewController";
import {useGoogleContext} from "../../../context/GoogleContextProvider";
import {useMapContext} from "../../../context/MapContextProvider";
import MapLastClickView from "./map/views/MapLastClickView";
import MapEngineViewController from "./map/MapEngineViewController";
import {useLayoutContext} from "../../../context/LayoutContextProvider";

export interface MapViewControllerProps {
  dimensions?: Dimensions;
}

const MapViewController = (props: MapViewControllerProps): JSX.Element => {
  const {googleInitialized} = useGoogleContext();
  const {streetViewSettings} = useMapContext();
  const {dimensions} = props;

  const {
    leftPaneCollapsed, toggleLeftPane,
    bottomPaneCollapsed, toggleBottomPane,
  } = useLayoutContext();

  const onLeftTabClicked = () => {
    toggleLeftPane();
  };

  const onBottomTabClicked = () => {
    toggleBottomPane();
  };

  if (!dimensions) {
    return (
      <Skeleton active/>
    )
  }

  return (
    <div style={{width: '100%', height: dimensions.height}}>
      <MapTabView
        dimensions={dimensions}
        anchor={'left'}
        collapsed={leftPaneCollapsed}
        onClick={onLeftTabClicked}
      />
      <MapTabView
        dimensions={dimensions}
        anchor={'bottom'}
        collapsed={bottomPaneCollapsed}
        onClick={onBottomTabClicked}
      />
      <MapMenuBarViewController dimensions={dimensions}/>
      {/*<MapRouteDestinationViewController dimensions={dimensions} />*/}
      {
        (googleInitialized && streetViewSettings && streetViewSettings.enabled) && (
          <MapStreetViewController/>
        )
      }

      <MapEngineViewController/>

      <MapContainer
        id={'map'}
        center={defaultLocation}
        zoom={defaultZoomLevel}
        inertia={true}
        // scrollWheelZoom={true}
        style={{width: '100%', height: '100%'}}
        keyboard={true}
        doubleClickZoom={false}
        dragging={true}
        boxZoom={true}
        tap={false}
        touchZoom={true}
        zoomControl={false}
        attributionControl={true}
      >
        <MapEventController dimensions={dimensions}/>

        <MapLayerViewController/>

        <MapRouteDestinationViewController/>

        <MapRouteSegmentViewController/>

        <MapGeoManController/>

        <MapCurrentUserView/>

        <MapLastClickView/>
      </MapContainer>
    </div>
  );
};

export default MapViewController;
