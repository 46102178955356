import {RouteDestination} from "../types/RouteDestination";


export const getWazeUrl = (destinations: RouteDestination[], travelMode?: string) => {
  const baseUrl = "https://www.waze.com/live-map/directions";
  const from = destinations[0].location;

  if (destinations.length === 1) {
    return `${baseUrl}?from=ll.${from.lat},${from.lng}`;
  }

  const to = destinations[destinations.length - 1].location;
  return `${baseUrl}?from=ll.${from.lat},${from.lng}&to=ll.${to.lat},${to.lng}`;
}

// https://learn.microsoft.com/en-us/bingmaps/articles/create-a-custom-map-url
export const getBingUrl = (destinations: RouteDestination[], travelMode?: string) => {
  const baseUrl = "https://www.bing.com/maps";

  const waypoints: string = destinations.map(it => {
    return `pos.${it.location.lat}_${it.location.lng}`;
  }).join('~');

  // limit=D&time=YYYYMMDDhhmm
  return `${baseUrl}?rtp=${waypoints}&mode=D`;
}

export const getGoogleUrl = (destinations: RouteDestination[], travelMode?: string) => {
  const baseUrl = 'https://www.google.com/maps/dir/?api=1';

  const origin = destinations[0];
  const originLocation = `${origin.location.lat},${origin.location.lng}`;

  if (destinations.length === 1) {
    return `${baseUrl}&origin=${originLocation}&travelmode=${travelMode || 'DRIVING'}`;
  }

  const destination = destinations[destinations.length - 1];
  const destinationLocation = `${destination.location.lat},${destination.location.lng}`;

  let url = `${baseUrl}&origin=${originLocation}&destination=${destinationLocation}&travelmode=${travelMode || 'DRIVING'}`;

  if (destinations.length === 2) {
    return url;
  }

  const waypoints: string = destinations.slice(1, destinations.length - 1).map(it => {
    return `${it.location.lat},${it.location.lng}`;
  }).join('|');

  return `${url}&waypoints=${waypoints}`;
}

export const launchNewTab = (url: string) => {
  window.open(url, "_blank");
}
