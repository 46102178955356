import {createContext} from "react";
import {MapContextMenu} from "../pages/map/types/MapContextMenu";
import {LatLng, LatLngBounds} from "leaflet";
import {StreetViewSettings} from "../pages/map/types/StreetViewSettings";

export const defaultLocation = new LatLng(34.0140017, -118.4132816);
export const defaultZoomLevel = 12;

export type MapContextType = {
  zoomLevel: number;

  setZoomLevel: (newZoomLevel: number) => void;

  zoomIn: () => void;

  zoomOut: () => void;

  bounds: LatLngBounds | null;

  setBounds: (bounds: LatLngBounds | null) => void;

  centerPoint: LatLng | null;

  setCenterPoint: (point: LatLng | null) => void;

  currentUserLocation: LatLng | null;

  setCurrentUserLocation: (location: LatLng | null) => void;

  lastClickLocation: LatLng | null;

  setLastClickLocation: (location: LatLng | null) => void;

  engine: string;

  setEngine: (engine: string) => void;

  contextMenu: MapContextMenu | null;

  showContextMenu: (menu: MapContextMenu) => void;

  hideContextMenu: () => void;

  streetViewSettings: StreetViewSettings | null;

  setStreetViewSettings: (settings: StreetViewSettings | null) => void;

  toggleStreetViewSettings: (location?: LatLng) => void;

  editRouteSegmentEnabled: boolean;

  setEditRouteSegmentEnabled: (flag: boolean) => void;
};

export const MapDefaultContext: MapContextType = {
  zoomLevel: defaultZoomLevel,
  setZoomLevel: (newZoomLevel: number) => {
  },
  bounds: null,
  setBounds: (newBounds: LatLngBounds | null) => {
  },
  zoomIn: () => {
  },
  zoomOut: () => {
  },
  currentUserLocation: null,
  setCurrentUserLocation: (location: LatLng | null) => {
  },
  lastClickLocation: null,
  setLastClickLocation: (location: LatLng | null) => {
  },
  centerPoint: defaultLocation,
  setCenterPoint: (point: LatLng | null) => {
  },
  engine: 'osm',
  setEngine: (engine: string) => {
  },
  contextMenu: null,
  showContextMenu: (menu: MapContextMenu) => {
  },
  hideContextMenu: () => {
  },
  streetViewSettings: null,
  setStreetViewSettings: (settings: StreetViewSettings | null) => {
  },
  toggleStreetViewSettings: (location?: LatLng) => {
  },
  editRouteSegmentEnabled: false,
  setEditRouteSegmentEnabled: (flag: boolean) => {}
};

export default createContext<MapContextType>(MapDefaultContext);
