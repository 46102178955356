import React from 'react';
import styled from "styled-components";
import {Dimensions} from "../types/Dimensions";
import RouteTableViewController from "./route/RouteTableViewController";
import {useRouteContext} from "../../../context/RouteContextProvider";
import RouteSummaryView from "./route/views/RouteSummaryView";
import RouteDetailsViewController from "./route/RouteDetailsViewController";
import RouteHeaderViewController from "./route/RouteHeaderViewController";
import RouteDepartureTimeViewController from "./route/RouteDepartureTimeViewController";

export interface PlacesTableViewControllerProps {
  dimensions?: Dimensions;
}

const RouteHeader = styled.div`
  //border-bottom: 1px solid #eee;
  padding: 10px 5px;
  background: #fafafa;
`;

const Container = styled.div`

`;

const RouteViewController = (props: PlacesTableViewControllerProps): JSX.Element | null => {
  const {dimensions} = props;
  const {destinations, overviewSegment} = useRouteContext();

  if (!dimensions?.height) {
    return null;
  }

  return (
    <Container style={{width: '100%', height: dimensions.height}}>
      <RouteHeaderViewController/>

      <RouteDetailsViewController/>

      <RouteHeader>
        <RouteDepartureTimeViewController/>
      </RouteHeader>
      {
        overviewSegment && (
          <RouteHeader style={{paddingBottom: 10, background: "#efefef", textAlign: "center"}}>
            <RouteSummaryView routeSegment={overviewSegment} destinationCount={destinations.length - 1}/>
          </RouteHeader>
        )
      }

      <RouteTableViewController/>
    </Container>
  );
};

export default RouteViewController;
