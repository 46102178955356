import {createContext} from "react";

export type LayoutContextType = {
  leftPaneCollapsed: boolean;
  toggleLeftPane: () => void;
  expandLeftPane: () => void;
  collapseLeftPane: () => void;

  bottomPaneCollapsed: boolean;
  toggleBottomPane: () => void;
  expandBottomPane: () => void;
  collapseBottomPane: () => void;

  selectedTool: string | null;
  setSelectedTool: (tool: string | null) => void;
};

export const LayoutDefaultContext: LayoutContextType = {
  leftPaneCollapsed: false,
  toggleLeftPane: () => {
  },
  expandLeftPane: () => {
  },
  collapseLeftPane: () => {
  },
  bottomPaneCollapsed: false,
  toggleBottomPane: () => {
  },
  expandBottomPane: () => {
  },
  collapseBottomPane: () => {
  },
  selectedTool: null,
  setSelectedTool: (tool: string | null) => {},
};

export default createContext<LayoutContextType>(LayoutDefaultContext);
