import React from 'react';
import {Marker, Popup} from "react-leaflet";
import L, {DragEndEvent} from 'leaflet';
import {useMapContext} from "../../../../../context/MapContextProvider";

interface MapCurrentUserViewProps {
}

const createIcon = () => {
  return L.divIcon({
    className: "map-icon-current-user",
    iconAnchor: [12, 12],
    popupAnchor: [-3, -16],
    html: ""
  });
};

const MapCurrentUserView = (props: MapCurrentUserViewProps): JSX.Element | null => {
  const {currentUserLocation, setCurrentUserLocation} = useMapContext();

  const icon = createIcon();

  const eventHandlers = {
    dragend: (event: DragEndEvent) => {
      setCurrentUserLocation(event.target.getLatLng());
    }
  };

  if (!currentUserLocation) {
    return null;
  }

  return (
    <Marker
      position={currentUserLocation}
      icon={icon}
      draggable
      eventHandlers={eventHandlers}
    >
      <Popup>
        That's You!
      </Popup>
    </Marker>
  );
};

export default MapCurrentUserView;
