import {createContext} from "react";
import {LatLng} from "leaflet";

export type GoogleContextType = {
  googleInitialized: boolean,
  placesService: google.maps.places.PlacesService | undefined,
  placesAutoCompleteService: google.maps.places.AutocompleteService | undefined,
  placesAutoCompleteSessionToken: google.maps.places.AutocompleteSessionToken | undefined,
  directionsService: google.maps.DirectionsService | undefined,
  geocoder: google.maps.Geocoder | undefined,

  getAddressByLocation: (
    location: LatLng
  ) => Promise<google.maps.GeocoderResult>,

  getDirections: (
    stops: LatLng[],
    travelMode: string
  ) => Promise<google.maps.DirectionsRoute>,

  getPlaceDetails: (
    placeId: string,
    sessionToken?: google.maps.places.AutocompleteSessionToken
  ) => Promise<google.maps.places.PlaceResult>
};

export const GoogleDefaultContext: GoogleContextType = {
  googleInitialized: false,
  placesService: undefined,
  placesAutoCompleteService: undefined,
  placesAutoCompleteSessionToken: undefined,
  directionsService: undefined,
  geocoder: undefined,
  getAddressByLocation: (location: LatLng) => {
    return new Promise((resolve, reject) => {
      reject("Not implemented");
    });
  },
  getDirections: (stops: LatLng[], travelMode: string): Promise<google.maps.DirectionsRoute> => {
    return new Promise((resolve, reject) => {
      reject("Not implemented");
    });
  },
  getPlaceDetails: (
    placeId: string,
    sessionToken?: google.maps.places.AutocompleteSessionToken
  ): Promise<google.maps.places.PlaceResult> => {
    return new Promise((resolve, reject) => {
      reject("Not implemented");
    });
  }
};

export default createContext<GoogleContextType>(GoogleDefaultContext);
