import React, {useEffect} from 'react';
import {useMap, useMapEvents} from "react-leaflet";
import {Dimensions} from "../../types/Dimensions";
import {useMapContext} from "../../../../context/MapContextProvider";
import {LeafletEvent, LeafletKeyboardEvent, LeafletMouseEvent} from "leaflet";
import {useRouteContext} from "../../../../context/RouteContextProvider";
import {RouteDestination} from "../../../../types/RouteDestination";
import {RouteSegment} from "../../../../types/RouteSegment";

export interface EventControllerProps {
  dimensions?: Dimensions;
}

const getFirstLayerClassNameUnderClick = (event: LeafletMouseEvent): string | null => {
  const layers: any[] = event.originalEvent.composedPath();

  if (layers.length === 0) {
    return null;
  }

  const firstLayer = layers[0];

  if (firstLayer.className.baseVal) {
    return firstLayer.className.baseVal.split(' ')[0];
  }

  return firstLayer.className.split(' ')[0];
}

const getObjectIdFromMouseClick = (event: LeafletMouseEvent, prefix: string): string | null => {
  const className = getFirstLayerClassNameUnderClick(event);
  if (!className) return null;

  const pref = `${prefix}_`;
  if (className.indexOf(pref) === -1) {
    return null;
  }

  return className.replaceAll(pref, '');
}

const MapEventController = (props: EventControllerProps): null => {
  const map = useMap();

  const {
    showContextMenu, hideContextMenu,
    centerPoint, setCenterPoint,
    zoomLevel, setZoomLevel, zoomIn, zoomOut,
    setBounds, toggleStreetViewSettings,
    setLastClickLocation,
    setStreetViewSettings, streetViewSettings
  } = useMapContext();

  const {
    getDestinationById,
    getRouteSegmentById,
    selectedDestination,
    setSelectedDestinationId,
    setSelectedRouteSegmentId
  } = useRouteContext();

  const getDestinationUnderClick = (event: LeafletMouseEvent): RouteDestination | null => {
    const destinationId = getObjectIdFromMouseClick(event, 'destination');
    if (!destinationId) return null;
    return getDestinationById(destinationId);
  };

  const getRouteSegmentUnderClick = (event: LeafletMouseEvent): RouteSegment | null => {
    const routeSegmentId = getObjectIdFromMouseClick(event, 'route_segment');
    if (!routeSegmentId) return null;
    return getRouteSegmentById(routeSegmentId);
  };

  useEffect(() => {
    map.invalidateSize();
  }, [props.dimensions]);

  useEffect(() => {
    if (centerPoint) {
      map.flyTo(centerPoint);
      setCenterPoint(null);
    }
  }, [centerPoint]);

  useEffect(() => {
    const currentZoom = map.getZoom();
    if (currentZoom !== zoomLevel) {
      map.setZoom(zoomLevel);
    }
  }, [zoomLevel]);

  useMapEvents({
    contextmenu: (event: LeafletMouseEvent) => {
      showContextMenu({
        location: event.latlng,
        position: {
          left: event.originalEvent.clientX,
          top: event.originalEvent.clientY
        },
        destination: getDestinationUnderClick(event),
        routeSegment: getRouteSegmentUnderClick(event),
      });
    },
    keydown: (event: LeafletKeyboardEvent) => {
      if (event.originalEvent.key === 'Escape') {
        setLastClickLocation(null);
        hideContextMenu();
        return;
      }

      if (event.originalEvent.key === 'z') {
        zoomIn();
        return;
      }

      if (event.originalEvent.key === 'Z') {
        zoomOut();
        return;
      }

      if (event.originalEvent.key === 's') {
        toggleStreetViewSettings(selectedDestination?.location);
        return;
      }
    },
    zoomend: (event: LeafletEvent) => {
      setZoomLevel(map.getZoom());
    },
    moveend: (event: LeafletEvent) => {
      setBounds(map.getBounds());
    },
    click: (event: LeafletMouseEvent) => {
      hideContextMenu();

      const routeSegment = getRouteSegmentUnderClick(event);
      if (routeSegment) {
        setSelectedRouteSegmentId(routeSegment.id);
        return;
      }

      const destination = getDestinationUnderClick(event);
      if (destination) {
        setSelectedDestinationId(destination.id);
        return;
      }

      setSelectedDestinationId(null);
      setSelectedRouteSegmentId(null);
      setLastClickLocation(event.latlng);
      setStreetViewSettings({location: event.latlng, enabled: streetViewSettings?.enabled === true});
    },
  });

  return null;
};

export default MapEventController;
