import React, {useContext, useMemo} from "react";
import AddressBookContext, {AddressBookContextType} from "./AddressBookContext";
import {
  AddressApi,
  AddressCreateRequest,
  AddressDeleteRequest,
  AddressResponse,
  AddressUpdateRequest,
  PagedCollectionAddressResponse
} from "../api/client";
import {buildConfiguration} from "../api";
import {useUserContext} from "./UserContextProvider";

export interface AddressBookContextProviderOptions {
  debug?: boolean;
}

export interface AddressBookContextProviderProps {
  opts?: AddressBookContextProviderOptions;
  children: JSX.Element;
}

export const AddressBookContextProvider: React.FC<AddressBookContextProviderProps> = (props) => {
  const {accessToken} = useUserContext();

  const addressApi = useMemo(() => {
    return new AddressApi(buildConfiguration(accessToken));
  }, [accessToken]);

  const filterAddresses = (search: string, order: string, offset: number, limit: number): Promise<PagedCollectionAddressResponse> => {
    return new Promise((success, failure) => {
      addressApi
        .filterAddresses(search, order, offset, limit)
        .then(result => {
          success(result.data);
        })
        .catch(err => {
          failure(err);
        });
    });
  };

  const createAddress = (request: AddressCreateRequest): Promise<AddressResponse> => {
    return new Promise((success, failure) => {
      addressApi.createAddress(request).then((response) => {
        success(response.data);
      }).catch(err => {
        failure(err);
      })
    });
  }

  const updateAddress = (request: AddressUpdateRequest): Promise<AddressResponse> => {
    return new Promise((success, failure) => {
      addressApi.updateAddress(request).then((response) => {
        success(response.data);
      }).catch(err => {
        failure(err);
      })
    });
  }

  const deleteAddress = (request: AddressDeleteRequest): Promise<AddressResponse> => {
    return new Promise((success, failure) => {
      addressApi.deleteAddress(request).then((response) => {
        success(response.data);
      }).catch(err => {
        failure(err);
      })
    });
  }

  const value = useMemo(() => {
    return {
      filterAddresses,
      createAddress,
      updateAddress,
      deleteAddress
    };
  }, [accessToken]);

  return (
    <AddressBookContext.Provider value={value}>
      {props.children}
    </AddressBookContext.Provider>
  );
};

export const useAddressBookContext: () => AddressBookContextType = () => {
  return useContext(AddressBookContext);
};
