import React from 'react';
import AppRoutes from "./AppRoutes";
import {BrowserRouter} from "react-router-dom";
import {GoogleContextProvider} from "./context/GoogleContextProvider";

function App() {
  return (
    <BrowserRouter>
      <GoogleContextProvider opts={{googleApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`}}>
        <AppRoutes/>
      </GoogleContextProvider>
    </BrowserRouter>
  );
}

export default App;
