import React, {CSSProperties} from 'react';
import {Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCar, faDollarSign} from "@fortawesome/free-solid-svg-icons";
import {ColumnsType} from "antd/es/table";
import styled from "styled-components";
import {RouteSegment} from "../../../../types/RouteSegment";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import DurationView from "../../../../components/DurationView";

interface DestinationInfo {
  icon: JSX.Element,
  label: string,
  value?: JSX.Element | string | null,
  style?: CSSProperties,
}

interface RouteSegmentDetailsTableViewProps {
  routeSegment: RouteSegment
}

const MissingData = styled.div`
  color: #ccc;
`;

const RouteSegmentDetailsTableView = (props: RouteSegmentDetailsTableViewProps): JSX.Element | null => {
  const {routeSegment} = props;

  const destinationInMiles = Math.round(routeSegment.distanceInMeters * 0.000621371 * 10) / 10;
  const timeInMinutes = Math.round(routeSegment.durationInSeconds / 60);
  const rideCost = 0.0;

  const infoColumns: ColumnsType<DestinationInfo> = [
    {
      dataIndex: "icon",
      width: 20,
    },
    {
      dataIndex: "value",
      render: (value: string, record: DestinationInfo, index: number) => {
        return (
          <div style={record.style}>{value}</div>
        );
      }
    }
  ];

  const infoData: DestinationInfo[] = [
    {
      icon: <FontAwesomeIcon icon={faCar}/>,
      label: "Distance",
      value: `${destinationInMiles} miles`,
      style: {color: "#888", fontSize: 11},
    },
    {
      icon: <FontAwesomeIcon icon={faClock}/>,
      label: "Duration",
      value: (<DurationView durationInMin={timeInMinutes}/>),
      style: {color: "#888", fontSize: 11},
    },
    {
      icon: <FontAwesomeIcon icon={faDollarSign}/>,
      label: "Cost",
      value: "n/a",
      style: {color: "#888", fontSize: 11},
    },
  ];

  return (
    <Table
      showHeader={false}
      size={"small"}
      columns={infoColumns}
      dataSource={infoData}
      rowKey={"label"}
      rowClassName={"table-row-align-top"}
      pagination={false}
    />
  );
};

export default RouteSegmentDetailsTableView;
