import { Tooltip } from 'antd';
import React from 'react';

interface DestinationActionsViewProps {
  onRemove: () => void;
}

const DestinationActionsView = (props: DestinationActionsViewProps): JSX.Element => {
  return (
    <Tooltip placement="right" title={'Remove from route'}>
      <div
        onClick={props.onRemove}
        style={{
          position: 'absolute',
          top: 5,
          right: 10,
          cursor: 'pointer',
          color: '#666',
          whiteSpace: "nowrap",
          fontSize: 14
        }}>
        ×
      </div>
    </Tooltip>
  );
};

export default DestinationActionsView;
