import React from 'react';
import {RouteSegment} from "../../../../../types/RouteSegment";
import {faCar, faPersonBiking, faPersonWalking, faLocationPin} from "@fortawesome/free-solid-svg-icons";
import {faCalendar, faClock} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useRouteContext} from "../../../../../context/RouteContextProvider";
import dayjs, {Dayjs} from "dayjs";
import {formatMinutesAsDaysHoursMinutes} from "../../../../../helpers/time";
import DurationView from "../../../../../components/DurationView";

interface RouteSummaryViewProps {
  routeSegment: RouteSegment;

  destinationCount?: number;

  onClick?: () => void;

  onDblClick?: () => void;

  arriveAt?: Dayjs | null;

  departAt?: Dayjs | null;
}

const RouteSummaryView = (props: RouteSummaryViewProps): JSX.Element => {
  const {route} = useRouteContext();

  const {routeSegment, destinationCount} = props;

  const destinationInMiles = Math.round(routeSegment.distanceInMeters * 0.000621371 * 10) / 10;
  const timeInMinutes = Math.round(routeSegment.durationInSeconds / 60);
  const rideCost = 0.0;

  const arriveAt = (route.departAt || dayjs()).add(timeInMinutes, 'minute');

  return (
    <div style={{fontSize: 11, display: "inline-block"}}>
      {
        destinationCount && (
          <>
            <div style={{display: "inline-block", marginRight: 5}}>
              <FontAwesomeIcon icon={faLocationPin}/>
            </div>

            <strong>{destinationCount}</strong> {destinationCount === 1 ? "stop" : "stops"}
          </>
        )
      }

      <div style={{display: "inline-block", marginRight: 5, marginLeft: 10}}>
        {route.travelMode === 'DRIVING' && (
          <FontAwesomeIcon icon={faCar}/>
        )}
        {route.travelMode === 'WALKING' && (
          <FontAwesomeIcon icon={faPersonWalking}/>
        )}
        {route.travelMode === 'BICYCLING' && (
          <FontAwesomeIcon icon={faPersonBiking}/>
        )}
      </div>

      <strong>{destinationInMiles}</strong> mi

      <div style={{display: "inline-block", marginRight: 5, marginLeft: 10}}>
        <FontAwesomeIcon icon={faClock}/>
      </div>

      <DurationView durationInMin={timeInMinutes} />

      <FontAwesomeIcon icon={faCalendar} style={{marginRight: 5, marginLeft: 8}}/>
      <strong style={{ marginRight: 2 }}>
        {arriveAt.format('hh:mm')}
      </strong>
      {arriveAt.format('a')}

      {/*&nbsp;&#183;&nbsp;<strong>${rideCost}</strong> total*/}
    </div>
  );
};

export default RouteSummaryView;
