import React from 'react';
import {Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCar, faPersonBiking, faPersonWalking} from "@fortawesome/free-solid-svg-icons";

interface TravelModeSelectorProps {
  travelMode: string;

  onTravelModeUpdated: (travelMode: string) => void;
}

const TravelModeSelector = (props: TravelModeSelectorProps): JSX.Element | null => {
  const {travelMode} = props;

  const setTravelMode = (mode: string) => {
    props.onTravelModeUpdated(mode);
  };

  return (
    <div style={{padding: 0, textAlign: 'center', marginBottom: 0}}>
      <Button
        size={'small'}
        type={travelMode === "DRIVING" ? 'primary' : 'default'}
        onClick={() => setTravelMode("DRIVING")}
        style={{width: 50, marginRight: 5}}
      >
        <FontAwesomeIcon icon={faCar}/>
      </Button>

      <Button
        size={'small'}
        type={travelMode === "WALKING" ? 'primary' : 'default'}
        onClick={() => setTravelMode("WALKING")}
        style={{width: 50, marginRight: 5}}
      >
        <FontAwesomeIcon icon={faPersonWalking}/>
      </Button>

      <Button
        size={'small'}
        type={travelMode === "BICYCLING" ? 'primary' : 'default'}
        onClick={() => setTravelMode("BICYCLING")}
        style={{width: 50, marginRight: 5}}
      >
        <FontAwesomeIcon icon={faPersonBiking}/>
      </Button>
    </div>
  );
};

export default TravelModeSelector;
