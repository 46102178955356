/* eslint-disable @typescript-eslint/no-unsafe-assignment, max-len */
import React from 'react';
import {TileLayer} from 'react-leaflet';

export interface MapBoxLayerProps {
  style: string;
}

const MapBoxLayer = (props: MapBoxLayerProps) => {
  const accessToken = process.env.REACT_APP_MAPBOX_API_KEY;
  const tileUrl = `https://api.mapbox.com/styles/v1/mapbox/${props.style}/tiles/{z}/{x}/{y}?access_token=${accessToken}`;

  return (
    <TileLayer
      url={tileUrl}
      zoomOffset={-1}
      tileSize={512}
      attribution='&copy; <a href="https://www.mapbox.com/contribute/">Mapbox</a>'
    />
  );
};

export default MapBoxLayer;
