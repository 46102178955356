import {createContext} from "react";
import {LatLng} from "leaflet";
import {v4 as uuidv4} from "uuid";
import {Route} from "../types/Route";
import {RouteSegment} from "../types/RouteSegment";
import {RouteDestination} from "../types/RouteDestination";
import {RouteApi} from "../api/client";
import dayjs from "dayjs";

export type RouteContextType = {
  routeApi: RouteApi;

  route: Route;

  updateRoute: (newRoute: Route) => void;

  destinations: RouteDestination[];

  overviewSegment: RouteSegment | null;

  setOverviewSegment: (path: RouteSegment | null) => void;

  routeSegments: RouteSegment[];

  setRouteSegments: (segments: RouteSegment[]) => void;

  addDestination: (stop: RouteDestination) => void;

  updateDestination: (destination: RouteDestination) => void;

  removeDestination: (id: string) => void;

  moveDestination: (id: string, location: LatLng) => void;

  orderDestinations: (destinations: RouteDestination[]) => void;

  setDestinations: (newStops: RouteDestination[]) => void;

  getDestinationById: (id: string) => RouteDestination | null;

  selectedDestinationId: string | null;

  setSelectedDestinationId: (id: string | null) => void;

  selectedDestination: RouteDestination | null;

  getRouteSegmentById: (id: string) => RouteSegment | null;

  selectedRouteSegmentId: string | null;

  setSelectedRouteSegmentId: (id: string | null) => void;

  selectedRouteSegment: RouteSegment | null;

  addDestinationToAddressBook: (id: string) => void;
  removeDestinationFromAddressBook: (id: string) => void;

  newRoute: () => void;
};

export const defaultRoute = {
  id: '',
  name: "My new route",
  travelMode: 'DRIVING',
  departureTime: dayjs(),
};

export const RouteDefaultContext: RouteContextType = {
  routeApi: new RouteApi(),
  route: defaultRoute,
  updateRoute: (newRoute: Route) => {
  },
  destinations: [],
  addDestination: (stop: RouteDestination) => {
  },
  updateDestination: (destination: RouteDestination) => {
  },
  removeDestination: (id: string) => {
  },
  moveDestination: (id: string, location: LatLng) => {
  },
  orderDestinations: (destinations: RouteDestination[]) => {

  },
  setDestinations: (destinations: RouteDestination[]) => {
  },

  getDestinationById: (id: string) => {
    return null;
  },
  selectedDestinationId: null,
  setSelectedDestinationId: (id: string | null) => {
  },
  selectedDestination: null,

  getRouteSegmentById: (id: string) => {
    return null;
  },
  selectedRouteSegmentId: null,
  setSelectedRouteSegmentId: (id: string | null) => {
  },
  selectedRouteSegment: null,

  overviewSegment: null,
  setOverviewSegment: (path: RouteSegment | null) => {
  },

  routeSegments: [],
  setRouteSegments: (paths: RouteSegment[]) => {
  },

  addDestinationToAddressBook: (id: string) => {
  },
  removeDestinationFromAddressBook: (id: string) => {
  },

  newRoute: () => {
  }
};

export default createContext<RouteContextType>(RouteDefaultContext);
