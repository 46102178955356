import React from 'react';
import {Modal} from "antd";
import styled from "styled-components";

interface RouteDetailsModalProps {
  open: boolean;
  onUpdated: () => void;
  onCancel: () => void;
}

const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #888;
`;

const RouteDetailsModal = (props: RouteDetailsModalProps): JSX.Element => {
  const handleOk = () => {
    props.onUpdated();
  }

  return (
    <Modal title="Settings" open={props.open} onOk={handleOk} okText={"Update Settings"}
           onCancel={() => props.onCancel()}>

    </Modal>
  );
};

export default RouteDetailsModal;
