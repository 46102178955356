import {Drawer} from 'antd';
import React, {useEffect, useState} from 'react';
import {faBars, faCar, faRoad, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import bgImg1 from "../../../assets/images/menu/bg1.jpg";
import bgImg2 from "../../../assets/images/menu/bg2.jpg";
import bgImg3 from "../../../assets/images/menu/bg3.jpg";
import bgImg4 from "../../../assets/images/menu/bg4.jpg";
import {useRouteContext} from "../../../context/RouteContextProvider";
import {useUserContext} from "../../../context/UserContextProvider";
import {useNavigate} from "react-router-dom";
import {useLayoutContext} from "../../../context/LayoutContextProvider";

const BottomImage = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 400px;
`;

const MenuItem = styled.div`
  padding-left: 50px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;

  &:hover {
    background: #eee;
  }
`;

const MenuItemIcon = styled.div`
  display: inline-block;
  width: 40px;
`;

const backgrounds = [bgImg1, bgImg2, bgImg3, bgImg4];

const SidebarMenu = (): JSX.Element => {
  const {newRoute} = useRouteContext();
  const {logout} = useUserContext();
  const navigate = useNavigate();

  const {
    setSelectedTool
  } = useLayoutContext();

  const [open, setOpen] = useState<boolean>(false);
  const [bgIndex, setBgIndex] = useState<number>(1);

  useEffect(() => {
    if (open) {
      setBgIndex(Math.floor(Math.random() * (backgrounds.length - 1)));
    }
  }, [open]);

  const handleMenuClick = (key: string): void => {
    if (key === "new_route") {
      newRoute();
    } else if (key === "saved_routes") {
      setSelectedTool("routes");
    } else if (key === "address_book") {
      setSelectedTool("addresses");
    } else if (key === "logout") {
      navigate("/", {replace: true});
      logout();
    }
    setOpen(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <FontAwesomeIcon icon={faBars} style={{cursor: "pointer"}} onClick={() => setOpen(true)}/>
      <Drawer
        title={(
          <div style={{paddingLeft: 25, fontStyle: "italic", fontFamily: "Mochiy Pop One"}}>
            Route & Ride
          </div>
        )}
        placement={"left"}
        closable={true}
        onClose={onClose}
        open={open}
        bodyStyle={{padding: 0}}
        style={{width: 400}}
      >
        <MenuItem style={{marginTop: 20}} onClick={() => handleMenuClick('new_route')}>
          <MenuItemIcon>
            <FontAwesomeIcon icon={faRoad} style={{fontSize: 20, verticalAlign: "top"}}/>
          </MenuItemIcon>
          <div style={{display: "inline-block"}}>
            PLAN NEW ROUTE
          </div>
        </MenuItem>

        <MenuItem style={{marginTop: 20}} onClick={() => handleMenuClick('saved_routes')}>
          <MenuItemIcon>
            <FontAwesomeIcon icon={faCar} style={{fontSize: 20, verticalAlign: "top"}}/>
          </MenuItemIcon>
          <div style={{display: "inline-block"}}>
            SAVED ROUTES
          </div>
        </MenuItem>

        <MenuItem style={{marginTop: 0}} onClick={() => handleMenuClick('address_book')}>
          <MenuItemIcon>
            <FontAwesomeIcon icon={faUser} style={{fontSize: 20, verticalAlign: "top"}}/>
          </MenuItemIcon>
          <div style={{display: "inline-block"}}>
            ADDRESS BOOK
          </div>
        </MenuItem>

        <MenuItem style={{marginTop: 20, color: "#666"}} onClick={() => handleMenuClick('logout')}>
          <MenuItemIcon>

          </MenuItemIcon>
          <div style={{display: "inline-block"}}>
            SIGN OUT
          </div>
        </MenuItem>

        <BottomImage>
          <img src={backgrounds[bgIndex]} key={bgIndex} alt={"bg"}/>
        </BottomImage>
      </Drawer>
    </>
  );
};

export default SidebarMenu;
