import {AuthApi, Configuration} from "./client";
import {AxiosHeaders} from "axios";

export const authAPI = new AuthApi();

export const buildAuthHeaders = (accessToken: string): AxiosHeaders => {
  return new AxiosHeaders({
    "Authorization": `Bearer ${accessToken}`,
  });
};

export const buildConfiguration = (accessToken: string | null): Configuration => {
  return new Configuration({
    baseOptions: {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }
  });
}
