import React from 'react';
import {RouteSegment} from "../../../../../types/RouteSegment";
import {Dayjs} from "dayjs";
import {faCalendar, faClock} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCar, faPersonBiking, faPersonWalking} from "@fortawesome/free-solid-svg-icons";
import {useRouteContext} from "../../../../../context/RouteContextProvider";
import {Col, Row} from 'antd';
import DurationView from "../../../../../components/DurationView";
import styled from "styled-components";

const Container = styled.div`
  color: #888;
  font-size: 11px;
  cursor: pointer;
`;

interface RouteLegViewProps {
  routeSegment: RouteSegment;

  onClick: () => void;

  onDblClick: () => void;

  arriveAt?: Dayjs | null;

  departAt?: Dayjs | null;
}

const RouteSegmentView = (props: RouteLegViewProps): JSX.Element => {
  const {route} = useRouteContext();
  const {routeSegment, arriveAt, departAt} = props;

  const destinationInMiles = Math.round(routeSegment.distanceInMeters * 0.000621371 * 10) / 10;
  const timeInMinutes = Math.round(routeSegment.durationInSeconds / 60);
  const rideCost = 0.0;

  return (
    <Container onClick={props.onClick} onDoubleClick={props.onDblClick}>
      <Row>
        <Col flex={1} style={{fontSize: 11}}>
          <div style={{display: "inline-block", marginRight: 5, marginLeft: 10}}>
            {route.travelMode === 'DRIVING' && (
              <FontAwesomeIcon icon={faCar}/>
            )}
            {route.travelMode === 'WALKING' && (
              <FontAwesomeIcon icon={faPersonWalking}/>
            )}
            {route.travelMode === 'BICYCLING' && (
              <FontAwesomeIcon icon={faPersonBiking}/>
            )}
          </div>

          <strong>{destinationInMiles}</strong> mi

          <div style={{display: "inline-block", marginRight: 5, marginLeft: 10}}>
            <FontAwesomeIcon icon={faClock}/>
          </div>

          <DurationView durationInMin={timeInMinutes}/>
          {/*&nbsp;&#183;&nbsp;<strong>${rideCost}</strong> total*/}
        </Col>
        <Col flex={"none"} style={{fontSize: 11, textAlign: "right"}}>
          {
            arriveAt && (
              <>
                <FontAwesomeIcon icon={faCalendar} style={{marginRight: 5}}/>
                <strong style={{marginRight: 2}}>
                  {arriveAt.format('hh:mm')}
                </strong>
                {arriveAt.format('a')}

              </>
            )
          }
        </Col>
      </Row>
    </Container>
  );
};

export default RouteSegmentView;
