import React, {useMemo} from 'react';
import styled from "styled-components";
import {Button, Card, Col, message, Row, Tag, Tooltip} from "antd";
import DestinationDetailsTableView from "./DestinationDetailsTableView";
import DestinationFormView from "./DestinationFormView";
import DestinationPhotosView from "./DestinationPhotosView";
import {useRouteContext} from "../../../../context/RouteContextProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook} from '@fortawesome/free-regular-svg-icons';
import ExternalMapButtons from "./ExternalMapButtons";

const Container = styled.div`

`;

const Header = styled.div`
  height: 40px;
  background: #f0f0f0;
  padding: 0 10px;
  border-bottom: 1px solid #ddd;
`;

const Body = styled.div`
  padding: 10px;
`;

const DestinationDetailsViewController = (): JSX.Element | null => {
  const {
    destinations,
    selectedDestination,
    addDestinationToAddressBook
  } = useRouteContext();

  const [messageApi, contextHolder] = message.useMessage();

  const destinationColor = useMemo(() => {
    if (!selectedDestination) {
      return "blue";
    }

    if (destinations[0].id === selectedDestination.id) {
      return "red";
    }

    if (destinations[destinations.length - 1].id === selectedDestination.id) {
      return "green";
    }

    return "blue";
  }, [destinations, selectedDestination]);

  if (!selectedDestination) {
    return null;
  }

  const addToAddressBook = () => {
    addDestinationToAddressBook(selectedDestination.id);
    messageApi.info('Address book has been updated.');
  };

  return (
    <Container>
      {contextHolder}
      <Header>
        <Row>
          <Col flex={1} style={{paddingTop: 10}}>
            <Tag color={destinationColor}>{selectedDestination.address}</Tag>
          </Col>
          <Col flex={"none"} style={{textAlign: "right", paddingTop: 6}}>
            <Button size={"small"} onClick={addToAddressBook} style={{marginRight: 15, width: 30}}>
              <Tooltip placement="top" title={'Add to the address book'}>
                <FontAwesomeIcon icon={faAddressBook} style={{color: '#666'}}/>
              </Tooltip>
            </Button>

            <ExternalMapButtons destination={selectedDestination}/>
          </Col>
        </Row>
      </Header>
      <Body>
        <Row>
          <Col flex={"auto"}>
            <Row>
              <Col span={14} style={{paddingRight: 10}}>
                <Card bodyStyle={{height: 270, overflow: "auto", padding: 10}}>
                  <DestinationFormView destination={selectedDestination}/>
                </Card>
              </Col>
              <Col span={10}>
                <Card bodyStyle={{padding: 0, height: 270, overflow: "auto"}}>
                  <DestinationDetailsTableView destination={selectedDestination}/>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col flex={"none"} style={{paddingLeft: 10}}>
            <DestinationPhotosView destination={selectedDestination}/>
          </Col>
        </Row>
      </Body>
    </Container>
  );
};

export default DestinationDetailsViewController;
