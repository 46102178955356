/* eslint-disable import/named, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment */
import {useEffect} from 'react';
import {useMap} from 'react-leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import L, {Layer} from 'leaflet';
import {useRouteContext} from "../../../../context/RouteContextProvider";
import {useMapContext} from "../../../../context/MapContextProvider";

const GEOMAN_EDIT_EVENT = 'pm:edit';

const getRouteSegment = (layer: any): { kind: string, id: string } | null => {
  const className = layer.options?.className || null;

  if (!className) {
    return null;
  }

  const klass = `${className}`;

  if (klass.includes('route_segment')) {
    return {
      kind: 'nest',
      id: klass.replace('route_segment_', ''),
    };
  }

  return null;
};

const MapGeoManController = (): null => {
  const map = useMap();
  const {selectedRouteSegment} = useRouteContext();
  const { editRouteSegmentEnabled, setEditRouteSegmentEnabled } = useMapContext();

  useEffect(() => {
    // if (editRouteSegmentEnabled) {
    //   map.pm.enableGlobalEditMode();
    // } else {
    //   map.pm.disableGlobalEditMode();
    // }
  }, [editRouteSegmentEnabled]);

  // useEffect(() => {
  //   map.on('pm:globaleditmodetoggled', (e: MapToolToggleEvent) => {
  //     // setSelectedEditTool(e.enabled ? MAP_EDITOR_TOOL_EDIT : null);
  //   });
  //
  //   map.on('pm:globaldragmodetoggled', (e: MapToolToggleEvent) => {
  //     // setSelectedEditTool(e.enabled ? MAP_EDITOR_TOOL_DRAG : null);
  //   });
  //
  //   map.on('pm:globalrotatemodetoggled', (e: MapToolToggleEvent) => {
  //     // setSelectedEditTool(e.enabled ? MAP_EDITOR_TOOL_ROTATE : null);
  //   });
  //
  //   return () => {
  //     map.removeEventListener('pm:globaleditmodetoggled');
  //     map.removeEventListener('pm:globaldragmodetoggled');
  //     map.removeEventListener('pm:globalrotatemodetoggled');
  //   };
  // }, [ setSelectedEditTool ]);

  const processRouteSegmentChanged = (routeSegmentId: string, e: any): void => {
    console.log('entity_changed', routeSegmentId, e.shape);
    const layer = e.layer;
    const shape = e.shape;

    // const newCoords = layer.getLatLngs() as LatLng[][];
    // const center = layer.getLatLng() as LatLng;
    // const radius = layer.getRadius() as number;
  };

  // when selections change, we should update selected nest layers to enable GeoMan and re-register them
  useEffect(() => {
    map.eachLayer((layer: Layer) => {
      const routeSegment = getRouteSegment(layer);

      if (!routeSegment) return;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const opts = (layer as any).options;

      if (routeSegment.id === selectedRouteSegment?.id) {
        opts.pmIgnore = false;

        layer.addEventListener(GEOMAN_EDIT_EVENT, (e: any) => {
          processRouteSegmentChanged(routeSegment.id, e);
        });

        L.PM.reInitLayer(layer);
      } else {
        opts.pmIgnore = true;

        layer.removeEventListener(GEOMAN_EDIT_EVENT);

        L.PM.reInitLayer(layer);
      }
    });

    return () => {
      map.eachLayer((layer: Layer) => {
        layer.removeEventListener(GEOMAN_EDIT_EVENT);
      });
    };
  }, [selectedRouteSegment]);

  return null;
};

export default MapGeoManController;
