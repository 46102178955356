import React from 'react';
import styled from 'styled-components';
import {minutesToDaysHoursMinutes} from "../helpers/time";

interface DurationViewProps {
  durationInMin: number;
  minLabel?: string;
  hourLabel?: string;
  dayLabel?: string;
}

const Fragment = styled.div`
  display: inline-block;
  margin-right: 3px;
`;

const Value = styled.div`
  display: inline-block;
  font-weight: bold;
  margin-right: 1px;
`;

const DurationView = (props: DurationViewProps): JSX.Element => {
  const { durationInMin, minLabel, hourLabel, dayLabel } = props;
  const dhm = minutesToDaysHoursMinutes(durationInMin);

  return (
    <>
      {
        (dhm.days > 0) && (
          <Fragment><Value>{dhm.days}</Value>{dayLabel || 'd'}</Fragment>
        )
      }

      {
        (dhm.hours > 0) && (
          <Fragment><Value>{dhm.hours}</Value>{hourLabel || 'h'}</Fragment>
        )
      }

      {
        (dhm.minutes > 0) && (
          <Fragment><Value>{dhm.minutes}</Value>{minLabel || 'm'}</Fragment>
        )
      }
    </>
  );
};

export default DurationView;
