import {Drawer, Input, Modal, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import {useLayoutContext} from "../../context/LayoutContextProvider";
import {AddressResponse} from "../../api/client";
import {useRouteContext} from "../../context/RouteContextProvider";
import {LatLng} from "leaflet";
import {v4 as uuidv4} from "uuid";
import {QuestionCircleOutlined} from '@ant-design/icons';
import AddressDetailsModal from "./components/AddressDetailsModal";
import AddressDetails from "./components/AddressDetails";
import styled from "styled-components";
import {useAddressBookContext} from "../../context/AddressBookContextProvider";

const {Search} = Input;

const DRAWER_WIDTH = 400;

interface AddressBookProps {
  open: boolean;
}

const SearchBar = styled.div`
  width: ${DRAWER_WIDTH - 25}px;
  padding: 10px;
  background: #eee;
`;

const ResultsBar = styled.div`
  width: ${DRAWER_WIDTH - 25}px;
  padding: 8px;
  background: #fafafa;
  text-align: center;
  font-size: 12px;
`;

const TableContainer = styled.div`
  overflow: scroll;
  height: calc(100% - 100px);
`;

const AddressBook = (props: AddressBookProps): JSX.Element => {
  const {addDestination} = useRouteContext();
  const [error, setError] = useState<string | null>(null);
  const [search, setSearch] = useState<string>('');
  const [order, setOrder] = useState<string>('name-asc');

  const {open} = props;
  const {
    setSelectedTool
  } = useLayoutContext();

  const {confirm} = Modal;

  const [addresses, setAddresses] = useState<AddressResponse[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [dirty, setDirty] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<AddressResponse | null>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const {filterAddresses, deleteAddress} = useAddressBookContext();

  useEffect(() => {
    if (!dirty) {
      return;
    }

    setDirty(false);

    filterAddresses(search, order, 0, 100)
      .then(response => {
        setAddresses(response.items || []);
        setTotal(response.total || 0);
      })
      .catch((err: Error) => {
        setError(err.message);
      });
  }, [dirty]);

  useEffect(() => {
    setDirty(open);
  }, [open]);

  const onClose = () => {
    setSelectedTool(null);
  };

  const onSearch = (query: string) => {
    setSearch(query);
    setDirty(true);
  };

  const editAddress = (address: AddressResponse) => {
    setSelectedAddress(address);
    setEditModalOpen(true);
  }

  const onAddressUpdated = () => {
    setSelectedAddress(null);
    setEditModalOpen(false);
    setDirty(true);
  }

  const onDeleteAddress = (address: AddressResponse) => {
    confirm({
      icon: <QuestionCircleOutlined/>,
      content: "Are you sure you want to remove this address?",
      okText: "Yes",
      onOk() {
        deleteAddress({id: address.id!})
          .then(() => {
            setDirty(true);
          })
          .catch((err: Error) => {
            setError(err.message);
          });
      }
    });
  };

  const addToRoute = (address: AddressResponse) => {
    const loc = new LatLng(address.location!.lat!, address.location!.lng!)
    addDestination({
      id: uuidv4(),
      placeId: address.googlePlaceId,
      name: address.name!,
      location: loc,
      address: address.address,
      phone: address.phone,
      url: address.url,
      addressId: address.id
    });
    setSelectedTool(null);
  };

  const columns = [
    {
      render: (col: string, address: AddressResponse) => {
        return (
          <AddressDetails
            address={address}
            onAddToRoute={() => addToRoute(address)}
            onEditAddress={() => editAddress(address)}
            onDeleteAddress={() => onDeleteAddress(address)}
          />
        );
      }
    }
  ];

  return (
    <Drawer
      title={(
        <div style={{paddingLeft: 25, fontStyle: "italic", fontFamily: "Mochiy Pop One"}}>
          Address Book
        </div>
      )}
      placement={"right"}
      closable={true}
      onClose={onClose}
      open={open}
      bodyStyle={{padding: 0}}
      style={{width: DRAWER_WIDTH}}
    >
      {
        total > 0 && (
          <>
            <SearchBar>
              <Search placeholder="Search address book" allowClear onSearch={onSearch}
                      style={{width: DRAWER_WIDTH - 45}}/>
            </SearchBar>
            <ResultsBar>
              Displaying {addresses.length} of {total} entries
            </ResultsBar>
          </>
        )
      }
      <TableContainer>
        {
          addresses && (
            <Table
              rowKey={'id'}
              size={'small'}
              showHeader={false}
              dataSource={addresses}
              columns={columns}
              style={{width: DRAWER_WIDTH - 30}}
              pagination={false}
              rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
            />
          )
        }
      </TableContainer>

      {
        selectedAddress && (
          <AddressDetailsModal
            address={selectedAddress}
            open={editModalOpen}
            onCancel={() => setEditModalOpen(false)}
            onAddressUpdated={onAddressUpdated}
          />
        )
      }
    </Drawer>
  );
};

export default AddressBook;
