import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const Shortcut = styled.div`
  display: inline-block;
`;

const Key = styled.span`
  border: 1px solid #ccc;
  padding: 0.1em 0.5em;
  margin: 3px;
  border-radius: 4px;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  background-color: #F7F7F7;
  vertical-align: middle;
`;

const Plus = styled.span`
  color: #888;
`;

const formatKeys = (keys: string): JSX.Element[] => {
  return keys.replaceAll('+', ' + ').split(' ').map(
    (key, index) =>
      key == '+' ? (<Plus key={index}>+</Plus>) : (<Key key={index}>{key}</Key>),
  );
};

interface ShortcutKeysProps {
  keys: string;
  style?: CSSProperties;
}

const ShortcutKeys = (props: ShortcutKeysProps): JSX.Element => {
  const { keys, style } = props;

  return (
    <Shortcut style={style}>
      {formatKeys(keys)}
    </Shortcut>
  );
};

export default ShortcutKeys;
