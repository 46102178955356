import React, {useState} from 'react';
import {Button, Col, Row, Tooltip} from "antd";
import {useRouteContext} from "../../../../context/RouteContextProvider";
import SettingsModal from "./modals/SettingsModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle, faMicrosoft, faWaze} from "@fortawesome/free-brands-svg-icons";
import {getBingUrl, getGoogleUrl, getWazeUrl, launchNewTab} from "../../../../helpers/url";
import styled from "styled-components";

const Container = styled.div`
  height: 40px;
  background: #f0f0f0;
  padding: 8px 5px;
  border-bottom: 1px solid #ddd;
`;

const RouteHeaderViewController = (): JSX.Element => {
  const {destinations, route} = useRouteContext();
  const [isSettingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);

  const openExternalUrl = (vendor: string) => {
    if (destinations.length < 2) {
      return;
    }

    let url: string | null = null;

    switch (vendor) {
      case "waze": {
        url = getWazeUrl(destinations);
        break;
      }
      case "bing": {
        url = getBingUrl(destinations);
        break;
      }
      case "google": {
        url = getGoogleUrl(destinations);
        break;
      }
      default: {
        break;
      }
    }

    if (!url) {
      return;
    }

    launchNewTab(url);
  }

  return (
    <Container>
      <Row>
        <Col flex={1}>
          <div style={{paddingTop: 5, paddingLeft: 5}}>
            Route
          </div>
        </Col>
        <Col flex={"none"}>
          <Button size={"small"} onClick={() => openExternalUrl('waze')} style={{marginRight: 3}}>
            <Tooltip placement="bottom" title={'Open in Waze'}>
              <FontAwesomeIcon icon={faWaze} style={{color: '#666'}}/>
            </Tooltip>
          </Button>
          <Button size={"small"} onClick={() => openExternalUrl('bing')} style={{marginRight: 3}}>
            <Tooltip placement="bottom" title={'Open in Bing Maps'}>
              <FontAwesomeIcon icon={faMicrosoft} style={{color:  '#666'}}/>
            </Tooltip>
          </Button>
          <Button size={"small"} onClick={() => openExternalUrl('google')} style={{marginRight: 3}}>
            <Tooltip placement="bottom" title={'Open in Google Maps'}>
              <FontAwesomeIcon icon={faGoogle} style={{color: '#666'}}/>
            </Tooltip>
          </Button>
          {/*<Button size={"small"} onClick={() => setSettingsModalOpen(true)}>*/}
          {/*  <SettingOutlined/>*/}
          {/*</Button>*/}
        </Col>
      </Row>
      <SettingsModal
        open={isSettingsModalOpen}
        onUpdated={() => setSettingsModalOpen(false)}
        onCancel={() => setSettingsModalOpen(false)}
      />
    </Container>
  );
};

export default RouteHeaderViewController;
