import React from 'react';
import styled from "styled-components";

interface RouteIndexViewProps {
  index: number;
}

const LineContainer = styled.div`
  position: absolute;
  top: 0;
  left: 20px;
  height: 100%;
  border-left: 2px solid #00b0ff;
`;

const RouteIndexView = (props: RouteIndexViewProps): JSX.Element => {
  return (
    <LineContainer>
      &nbsp;
    </LineContainer>
  );
};

export default RouteIndexView;
