import React, {useState} from 'react';
import {useMapContext} from '../../../../context/MapContextProvider';
import styled from "styled-components";
import mapOsm from "../../../../assets/images/maps/osm.png";
import mapGoogleHybrid from "../../../../assets/images/maps/google-hybrid.png";
import mapGoogleRoadmap from "../../../../assets/images/maps/google-roadmap.png";
import mapGoogleSatellite from "../../../../assets/images/maps/google-satellite.png";
import mapGoogleTerrain from "../../../../assets/images/maps/google-terrain.png";
import mapMapboxDark from "../../../../assets/images/maps/mapbox-dark-v11.png";
import mapMapboxLight from "../../../../assets/images/maps/mapbox-light-v11.png";
import mapMapboxOutdoors from "../../../../assets/images/maps/mapbox-outdoors-v12.png";
import mapMapboxNavDay from "../../../../assets/images/maps/mapbox-navigation-day-v1.png";
import mapMapboxNavNight from "../../../../assets/images/maps/mapbox-navigation-night-v1.png";
import mapMapboxStreets from "../../../../assets/images/maps/mapbox-streets-v12.png";
import mapMapboxSatellite from "../../../../assets/images/maps/mapbox-satellite-v9.png";
import mapMapboxSatelliteStreets from "../../../../assets/images/maps/mapbox-satellite-streets-v12.png";

const ENGINE_VIEW_SIZE = 75;

const SelectedContainer = styled.div`
  position: absolute;
  width: ${ENGINE_VIEW_SIZE}px;
  height: ${ENGINE_VIEW_SIZE}px;
  bottom: 30px;
  left: 40px;
  z-index: 900;
  border-radius: 10px;
  background: white;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  padding: 1px;
  cursor: pointer;
`;

const OptionsContainer = styled.div`
  position: absolute;
  width: ${ENGINE_VIEW_SIZE}px;
  height: 310px;
  bottom: 115px;
  left: 40px;
  z-index: 900;
  border-radius: 10px;
  background: white;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  padding: 1px;
  cursor: pointer;
  overflow: auto;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const OptionContainer = styled.div`
  border-radius: 10px;
  border: 2px solid #ccc;
  font-size: 10px;
  text-align: center;
  position: relative;
  margin-bottom: 5px;
  
  &:hover {
    border: 2px solid #00b0ff;
  }
`;

const Label = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  width: ${ENGINE_VIEW_SIZE}px;
  font-size: 11px;
  background: white;
  color: #666;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 3px 1px;
`;

const OptionLabel = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  width: ${ENGINE_VIEW_SIZE - 10}px;
  font-size: 9px;
  color: #666;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  vertical-align: bottom;
  background: #eee;
  padding: 3px 1px;
`;

const MapImage = styled.img`
  width: 100%; 
  border-radius: 10px;
`;

interface SelectOption {
  label: string;
  value: string;
  image?: string;
}

const options: SelectOption[] = [
  {
    label: 'Streets',
    value: 'osm',
    image: mapOsm,
  },
  {
    label: 'Roads',
    value: 'google-roadmap',
    image: mapGoogleRoadmap,
  },
  {
    label: 'Satellite',
    value: 'google-satellite',
    image: mapGoogleSatellite,
  },
  {
    label: 'Terrain',
    value: 'google-terrain',
    image: mapGoogleTerrain,
  },
  {
    label: 'Hybrid',
    value: 'google-hybrid',
    image: mapGoogleHybrid,
  },
  {
    label: 'Streets',
    value: 'mapbox-streets-v12',
    image: mapMapboxStreets,
  },
  {
    label: 'Outdoors',
    value: 'mapbox-outdoors-v12',
    image: mapMapboxOutdoors,
  },
  {
    label: 'Light',
    value: 'mapbox-light-v11',
    image: mapMapboxLight,
  },
  {
    label: 'Dark',
    value: 'mapbox-dark-v11',
    image: mapMapboxDark,
  },
  {
    label: 'Satellite',
    value: 'mapbox-satellite-v9',
    image: mapMapboxSatellite,
  },
  {
    label: 'Satellite Streets',
    value: 'mapbox-satellite-streets-v12',
    image: mapMapboxSatelliteStreets,
  },
  {
    label: 'Nav Day',
    value: 'mapbox-navigation-day-v1',
    image: mapMapboxNavDay,
  },
  {
    label: 'Nav Night',
    value: 'mapbox-navigation-night-v1',
    image: mapMapboxNavNight,
  },
];

const MapEngineViewController = (): JSX.Element | null => {
  const [selectorVisible, setSelectorVisible] = useState<boolean>(false);
  const {
    engine,
    setEngine,
  } = useMapContext();

  const toggleSelector = () => {
    setSelectorVisible(!selectorVisible);
  };

  const selectEngine = (val: string) => {
    setEngine(val);
    setSelectorVisible(false);
  };

  const selectedEngine = options.find(it => it.value === engine);

  if (!selectedEngine) {
    return null;
  }

  return (
    <>
      <SelectedContainer onClick={toggleSelector}>
        <MapImage src={selectedEngine.image} />
        <Label>{selectedEngine.label}</Label>
      </SelectedContainer>
      {
        selectorVisible && (
          <OptionsContainer>
            <div style={{padding: 2}}>
              {
                options.map(it => {
                  return (
                    <OptionContainer key={it.value} onClick={() => selectEngine(it.value)}>
                      <MapImage src={it.image} />
                      <OptionLabel>
                        {it.label}
                      </OptionLabel>
                    </OptionContainer>
                  )
                })
              }
            </div>
          </OptionsContainer>
        )
      }
    </>
  );
};

export default MapEngineViewController;
