import React from 'react';
import {Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {ColumnsType} from "antd/es/table";
import styled from "styled-components";
import {RouteSegment} from "../../../../types/RouteSegment";
import {RouteSegmentInstruction} from "../../../../types/RouteSegmentInstruction";
import DurationView from "../../../../components/DurationView";

interface RouteSegmentDirectionsTableViewProps {
  routeSegment: RouteSegment
}

const MissingData = styled.div`
  color: #ccc;
`;

const InstructionValue = styled.div`
  text-align: right;
  font-size: 12px;
  color: #666;
`;

const getManeuverIcon = (maneuver: string): JSX.Element => {
  if (maneuver === "turn-right") {
    return (
      <FontAwesomeIcon icon={faArrowRight}/>
    );
  }

  if (maneuver === "turn-left") {
    return (
      <FontAwesomeIcon icon={faArrowLeft}/>
    );
  }

  return (
    <FontAwesomeIcon icon={faArrowUp}/>
  );
}

const getShortDistance = (distanceInMeters: number | null): JSX.Element => {
  if (!distanceInMeters) return <></>;
  const destinationInMiles = Math.round(distanceInMeters * 0.000621371 * 10) / 10;
  if (destinationInMiles > 0) {
    return (<InstructionValue><strong>{destinationInMiles}</strong>mi</InstructionValue>);
  }

  const destinationInFeet = Math.round(distanceInMeters * 3.28084);
  return (<InstructionValue><strong>{destinationInFeet}</strong>ft</InstructionValue>);
}

const getShortTime = (durationInSeconds: number | null): JSX.Element => {
  if (!durationInSeconds) return <></>;
  const timeInMinutes = Math.round(durationInSeconds / 60);
  if (timeInMinutes > 0) {
    return (<InstructionValue><strong>{timeInMinutes}</strong>m</InstructionValue>);
  }

  return (<InstructionValue><strong>{durationInSeconds}</strong>s</InstructionValue>);
}

const RouteSegmentDirectionsTableView = (props: RouteSegmentDirectionsTableViewProps): JSX.Element | null => {
  const {routeSegment} = props;

  const infoColumns: ColumnsType<RouteSegmentInstruction> = [
    {
      dataIndex: "maneuver",
      render: (value: string, record: RouteSegmentInstruction, index: number) => {
        return getManeuverIcon(value);
      },
      width: 30,
    },
    {
      dataIndex: "details",
      render: (value: string, record: RouteSegmentInstruction, index: number) => {
        return (
          <div style={{ fontSize: 12, color: "#666" }} dangerouslySetInnerHTML={{__html: value}}/>
        );
      }
    },
    {
      dataIndex: "distanceInMeters",
      render: (value: string, record: RouteSegmentInstruction, index: number) => {
        return getShortDistance(record.distanceInMeters);
      }
    },
    {
      dataIndex: "durationInSeconds",
      render: (value: string, record: RouteSegmentInstruction, index: number) => {
        return getShortTime(record.durationInSeconds);
      }
    }
  ];

  return (
    <Table
      rowKey={"id"}
      showHeader={false}
      size={"small"}
      columns={infoColumns}
      dataSource={routeSegment.instructions}
      rowClassName={"table-row-align-top"}
      pagination={false}
    />
  );
};

export default RouteSegmentDirectionsTableView;
