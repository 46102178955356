import React, {useEffect, useState} from 'react';
import {Input, Modal} from "antd";
import {useRouteContext} from "../../../../../context/RouteContextProvider";
import TextArea from "antd/es/input/TextArea";
import styled from "styled-components";
import TravelModeSelector from "./TravelModeSelector";

interface RouteDetailsModalProps {
  open: boolean;
  onUpdated: () => void;
  onCancel: () => void;
}

const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #888;
`;

const RouteDetailsModal = (props: RouteDetailsModalProps): JSX.Element => {
  const {route, updateRoute} = useRouteContext();
  const [name, setName] = useState<string>(route.name);
  const [description, setDescription] = useState<string>(route.name);
  const [travelMode, setTravelMode] = useState<string>(route.travelMode);

  useEffect(() => {
    setName(route.name);
    setDescription(route.description || '');
    setTravelMode(route.travelMode)
  }, [route]);

  const handleOk = () => {
    updateRoute({
      ...route,
      ...{
        name: name,
        description: description,
        travelMode: travelMode,
      }
    });
    props.onUpdated();
  }

  return (
    <Modal title="Route Details" open={props.open} onOk={handleOk} okText={"Update Route"}
           onCancel={() => props.onCancel()}>

      <div style={{marginTop: 20}}>
        <TravelModeSelector
          travelMode={travelMode}
          onTravelModeUpdated={setTravelMode}
        />
      </div>

      <Label>
        Route Name
      </Label>
      <Input
        value={name}
        placeholder={'Route name'}
        style={{marginBottom: 5}}
        onChange={(event) => setName(event.target.value)}
      />

      <Label>
        Description
      </Label>
      <TextArea
        value={description}
        placeholder={'Description'}
        onChange={(event) => setDescription(event.target.value)}
        style={{marginBottom: 5, height: 60}}
      />

    </Modal>
  );
};

export default RouteDetailsModal;
