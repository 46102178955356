import React, {useContext, useEffect, useMemo, useState} from "react";
import GoogleContext, {GoogleContextType} from "./GoogleContext";
import {Loader} from "@googlemaps/js-api-loader";
import {LatLng} from "leaflet";
import {toGoogleLocation} from "../helpers/google";
import {first} from "rxjs";

export interface GoogleContextProviderOptions {
  googleApiKey: string;
  debug?: boolean;
}

const apiOptions = {};

export interface GoogleContextProviderProps {
  opts: GoogleContextProviderOptions;
  children: JSX.Element;
}

export const GoogleContextProvider: React.FC<GoogleContextProviderProps> = (props) => {
  const apiKey = props.opts.googleApiKey;

  const [googleInitialized, setGoogleInitialized] = useState<boolean>(false);
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | undefined>(undefined);
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | undefined>(undefined);
  const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService | undefined>(undefined);
  const [placesAutoCompleteService, setPlacesAutoCompleteService] = useState<google.maps.places.AutocompleteService | undefined>(undefined);
  const [placesAutoCompleteSessionToken, setPlacesAutoCompleteSessionToken] = useState<google.maps.places.AutocompleteSessionToken | undefined>(undefined);

  const initServices = async () => {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      await new Loader({
        apiKey,
        ...{
          libraries: ['places', 'geometry'],
          ...apiOptions
        }
      }).load();
    }
  }

  useEffect(() => {
    initServices().then(() => {
      if (!window.google) throw new Error('[react-google-places-autocomplete]: Google script not loaded');
      if (!window.google.maps) throw new Error('[react-google-places-autocomplete]: Google maps script not loaded');
      if (!window.google.maps.places) throw new Error('[react-google-places-autocomplete]: Google maps places script not loaded');

      setGeocoder(new google.maps.Geocoder());
      setPlacesService(new google.maps.places.PlacesService(document.createElement('div')));
      setPlacesAutoCompleteService(new google.maps.places.AutocompleteService());
      setPlacesAutoCompleteSessionToken(new google.maps.places.AutocompleteSessionToken());
      setDirectionsService(new google.maps.DirectionsService());
      setGoogleInitialized(true);

      console.log("Google context initialized...");
    }).catch(error => {
      console.error(error);
    });
  }, []);

  // useImperativeHandle(ref, () => ({
  //   getSessionToken: () => {
  //     return sessionToken;
  //   },
  //   refreshSessionToken: () => {
  //     setSessionToken(new google.maps.places.AutocompleteSessionToken());
  //   }
  // }), [sessionToken]);

  const getAddressByLocation = (location: LatLng): Promise<google.maps.GeocoderResult> => {
    return new Promise<google.maps.GeocoderResult>((resolve, reject) => {
      // TODO: figure out why the first time geocoder is undefined
      const geocoderInstance = geocoder || new google.maps.Geocoder();

      if (!geocoderInstance) {
        reject("Geocoder is not initialized");
        return;
      }

      const request: google.maps.GeocoderRequest = {
        location: {
          lat: location.lat,
          lng: location.lng,
        }
      }

      geocoderInstance.geocode(request, (response: null | google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === google.maps.GeocoderStatus.OK && response) {
          resolve(response[0]);
          return;
        }
        reject("Address could not be found");
      }).then((result) => {
        // console.log(result);
      }).catch(error => {
        console.log(error);
      });
    });
  }

  const getPlaceDetails = (
    placeId: string,
    sessionToken?: google.maps.places.AutocompleteSessionToken
  ): Promise<google.maps.places.PlaceResult> => {
    return new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
      if (!placesService) {
        reject("Places service is not available");
        return;
      }

      const request: google.maps.places.PlaceDetailsRequest = {
        placeId: placeId,
        sessionToken: sessionToken || placesAutoCompleteSessionToken
      }

      placesService.getDetails(request, (
        result: google.maps.places.PlaceResult | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status === "OK" && result) {
          console.log(result);
          resolve(result)
        } else {
          reject(status)
        }
      });
    })
  }

  const getDirections = (
    stops: LatLng[],
    travelMode: string,
  ): Promise<google.maps.DirectionsRoute> => {
    return new Promise((resolve, reject) => {
      if (stops.length < 2) {
        reject("Origin and details must be provided");
        return;
      }

      if (!directionsService) {
        reject("Direction service is not available");
        return;
      }

      const origin = toGoogleLocation(stops[0]);
      const destination = toGoogleLocation(stops[stops.length - 1]);
      let waypoints: google.maps.DirectionsWaypoint[] = [];

      if (stops.length > 2) {
        waypoints = stops.slice(1, stops.length - 1).map(stop => {
          return {
            location: toGoogleLocation(stop),
            stopover: true,
          };
        });
      }

      const request = {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: travelMode as google.maps.TravelMode,
      };

      directionsService.route(request, function (
        response: google.maps.DirectionsResult | null,
        status: google.maps.DirectionsStatus
      ) {
        if (status === 'OK' && response) {
          console.log(response);

          const firstRoute = response.routes[0];

          if (!firstRoute) {
            reject("No routes found");
            return;
          }

          resolve(firstRoute);
        } else {
          reject(status);
        }
      });
    });
  };

  const value: GoogleContextType = useMemo<GoogleContextType>(() => {
    return {
      googleInitialized,
      placesService,
      directionsService,
      placesAutoCompleteService,
      placesAutoCompleteSessionToken,
      geocoder,
      getAddressByLocation,
      getDirections,
      getPlaceDetails,
    }
  }, [googleInitialized, placesService, directionsService, placesAutoCompleteService, geocoder]);

  return (
    <GoogleContext.Provider value={value}>
      {props.children}
    </GoogleContext.Provider>
  );
};

export const useGoogleContext: () => GoogleContextType = () => {
  return useContext(GoogleContext);
};
