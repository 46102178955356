import React from "react";
import styled from "styled-components";
import {Button, Modal} from "antd";
import {signInWithPopup} from "firebase/auth";
import {auth, facebookAuthProvider} from '../../../firebase';
import {FacebookOutlined} from "@ant-design/icons";
import {UserCredential} from "@firebase/auth";

const Container = styled.div`
`;

interface LoginWithFacebookProps {
  onUserLoggedInWithFacebook: (creds: UserCredential) => void;
}

const LoginWithFacebook = (props: LoginWithFacebookProps): JSX.Element => {
  const signInWithFacebook = async () => {
    try {
      const response = await signInWithPopup(auth, facebookAuthProvider);
      props.onUserLoggedInWithFacebook(response);
    } catch (err: any) {
      if (err.code === 'auth/account-exists-with-different-credential') {
        Modal.error({
          title: 'Facebook Login',
          content: 'Your Facebook email is already associated with an email based account. Please login with your email and password instead.',
        });
      } else {
        console.error(err);
      }
    }
  };

  return (
    <Container>
      <Button
        icon={(<FacebookOutlined/>)}
        onClick={signInWithFacebook}
        style={{
          width: 180,
          // backgroundColor: '#4267b2',
          // color: "white",
          marginBottom: 10,
          textAlign: "left"
        }}>
        Login with Facebook
      </Button>
    </Container>
  );
};

export default LoginWithFacebook;
