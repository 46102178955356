import React, {useState} from 'react';
import {useRouteContext} from "../../../../context/RouteContextProvider";
import styled from "styled-components";
import bgImg from "../../../../assets/images/route/bg.png";
import RouteDetailsModal from "./modals/RouteDetailsModal";
import {EditOutlined, SettingOutlined} from "@ant-design/icons";
import { Tooltip } from 'antd';

const Container = styled.div`
  height: 80px;
  padding: 10px;
  background: url(${bgImg}) no-repeat;
  background-size: cover;
  width: 100%;
  border-bottom: 1px solid #eee;
`;

const RouteDetailsViewController = (): JSX.Element | null => {
  const {route} = useRouteContext();
  const [isSettingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);

  return (
    <Container>
      <div style={{ float: "right" }}>
        <Tooltip placement="right" title={'Edit route details'}>
          <EditOutlined onClick={() => setSettingsModalOpen(true)} />
        </Tooltip>
      </div>

      <div style={{fontSize: 18, fontWeight: 'bold'}}>
        {route.name}
      </div>
      <div style={{fontSize: 12, marginTop: 5}}>
        {route.description}
      </div>

      <RouteDetailsModal
        open={isSettingsModalOpen}
        onUpdated={() => setSettingsModalOpen(false)}
        onCancel={() => setSettingsModalOpen(false)}
      />
    </Container>
  );
};

export default RouteDetailsViewController;
