import React from 'react';
import {DatePicker} from "antd";
import {useRouteContext} from "../../../../context/RouteContextProvider";
import {Dayjs} from "dayjs";

const RouteDetailsViewController = (): JSX.Element | null => {
  const {route, updateRoute} = useRouteContext();

  const onDepartureTimeSelected = (date: Dayjs | null, dateString: string) => {
    updateRoute({...route, ...{ departAt: date }})
  };

  return (
    <div>
      <DatePicker
        showTime
        allowClear
        style={{width: "100%"}}
        defaultValue={route.departAt || undefined}
        format={"MM/DD/YYYY hh:mm a"}
        placeholder={"When are you planning to leave?"}
        onChange={onDepartureTimeSelected}
      />
    </div>
  );
};

export default RouteDetailsViewController;
