import React from 'react';
import {Pane} from "react-leaflet";
import MapRouteDestinationView from "./views/MapRouteDestinationView";
import {useRouteContext} from "../../../../context/RouteContextProvider";

const MapRouteDestinationViewController = (): JSX.Element | null => {
  const {destinations, moveDestination, setSelectedDestinationId, selectedDestinationId} = useRouteContext();

  return (
    <Pane name="stops_pane" style={{zIndex: 500}}>
      {
        destinations.map((destination, index) => {
          return (
            <MapRouteDestinationView
              key={destination.id}
              destination={destination}
              index={index}
              selected={selectedDestinationId === destination.id}
              total={destinations.length}
              onMoved={(loc) => moveDestination(destination.id, loc)}
              onClicked={() => setSelectedDestinationId(destination.id)}
            />
          );
        })
      }
    </Pane>
  );
};

export default MapRouteDestinationViewController;
