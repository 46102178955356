import React, {useState} from 'react';
import {Button, Tooltip} from "antd";
import {AimOutlined, Loading3QuartersOutlined} from "@ant-design/icons";
import {useMapContext} from "../../../../../context/MapContextProvider";
import {LatLng} from "leaflet";
import {useRouteContext} from "../../../../../context/RouteContextProvider";

export interface MapLocateButtonProps {
}

const MapLocateButton = (props: MapLocateButtonProps): JSX.Element | null => {
  const [locating, setLocating] = useState<boolean>(false);
  const {setCenterPoint, setLastClickLocation, setStreetViewSettings, streetViewSettings} = useMapContext();
  const {setSelectedDestinationId} = useRouteContext();

  if (!("geolocation" in navigator)) {
    return null;
  }

  const locateUser = () => {
    setLocating(true);
    navigator.geolocation.getCurrentPosition(function (position) {
      setLocating(false);
      const location = new LatLng(position.coords.latitude, position.coords.longitude);
      setCenterPoint(location);

      setSelectedDestinationId(null);
      setLastClickLocation(location);
      setStreetViewSettings({location: location, enabled: streetViewSettings?.enabled === true})
    });
  }

  return (
    <Tooltip placement="bottom" title={'Add current location'}>
      <Button size={'middle'} onClick={locateUser} style={{
        height: 37,
        boxShadow: "0 2px 9px rgba(0, 0, 0, 0.2)"
      }}>
        {
          locating ? (
            <Loading3QuartersOutlined spin/>
          ) : (
            <AimOutlined/>
          )
        }
      </Button>
    </Tooltip>
  );
};

export default MapLocateButton;
